import './valueNotice.less'
import NavBar from '@/component/NavBar/NavBar';

const Record = (props:any) => {
  return (
    <div className='withdraw-record-page'>
      <NavBar title="充值说明" />

      <section className="withdraw-value-notice">
        {
          <>
            <div>
              <h3>1.什么是钻石？</h3>
              <div>钻石是平台上的虚拟货币。钻石只能在平台内消耗使用，不能提现。</div>
              <h3>2.如何充值？</h3>
              <div>在小程序内点击【充值】-选择充值账号-选择充值金额-【确认支付】。</div>
              <h3>3.充值后是否可退还或提现？</h3>
              <div>充值后的余额可用于在平台内的消费，无法退还且不可提现，在充值操作时，请确认无误后再进行支付。</div>
            </div>
          </>
        }
      </section>
    </div>
  )
}

export default Record