interface setUserInfoIn{
  token: string;
  avatar: string;
  mobile?: string;
  user_id: string;
  user_nickname: string;
  signature?: string;
  invite_code?: string;
}

export const setUserInfo=(userInfo:setUserInfoIn)=>({
  type: 'SET_USER',
  userInfo: userInfo,
})