import React, { Component } from "react";
import { DownOutline } from 'antd-mobile-icons'
import './arrow.less'

interface Props {
  front?: string;
  behind?: string;
}

interface State {}

export default class UserDrawer extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    // const { front = '#CC85FF', behind = '#CC85FF' } = props;
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className="arrow-box">
        <DownOutline className="first-arrow" />
        <DownOutline className="second-arrow" />
        <DownOutline className="third-arrow" />
      </div>
    )
  }
}