import { useEffect, useState } from "react";
import NavBar from '@/component/NavBar/NavBar';
import "./MyInfo.less";
import { Form, Input, Button, Toast } from 'antd-mobile'
import { getBankInfo, postMobileCode } from '@/api/bank'
// import { useHistory } from 'react-router-dom'
import { inputScrollView } from '@/utils/common'
import { AccountData } from '@/interface/my-info'

const MyInfo = () => {
  const [form] = Form.useForm()
  // const [history] = useState(useHistory())

  const onFinish = (values: any) => {
    const data = {
      ...values,
    }
    postMobileCode(data).then(() => {
      Toast.show('提交成功')

      setTimeout(() => {
        leftButton()
      }, 500)
    })
  }

  const verifyBankNo = (no: string) => {
    return /^([1-9]{1})(\d{12,18})$/.test(no)
  }

  const verifyAlipay = (no: string) => {
    return /^(?:1[3-9]\d{9}|[a-zA-Z0-9]\w*@[a-zA-Z0-9]+.[a-zA-Z]{2,4})$/.test(no)
  }

  const checkBankNo = (_: any, no: string) => {
    if ((!alipay_account && !no) || (!no && (bank_branch_name || bank_counter_phone || bank_name))) {
      return Promise.reject(new Error('银行卡号不能为空'))
    } else if ((verifyAlipay(alipay_account) && !no) || verifyBankNo(no)) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('银行卡号格式不正确'))
  }

  const checkBankName = (_: any, no: string) => {
    return no || (!verifyBankNo(bank_card) && !bank_branch_name && !bank_counter_phone && verifyAlipay(alipay_account)) ? Promise.resolve() : Promise.reject(new Error('请输入开户行'))
  }

  const checkBankBranchName = (_: any, no: string) => {
    return no || (!verifyBankNo(bank_card) && !bank_name && !bank_counter_phone && verifyAlipay(alipay_account)) ? Promise.resolve() : Promise.reject(new Error('请输入开户支行'))
  }

  const checkMobile = (_: any, no: string) => {
    if ((!verifyBankNo(bank_card) && !bank_name && !bank_branch_name && verifyAlipay(alipay_account)) || /^1[3-9]\d{9}$/.test(no)) {
      return Promise.resolve()
    } else if (!no) {
      return Promise.reject(new Error('手机号不能为空'))
    } else return Promise.reject(new Error('手机号格式不正确'))
  }

  const checkAlipay = (_: any, no: string) => {
    if (!bank_card && !no) {
      return Promise.reject(new Error('支付宝账号不能为空'))
    } else if ((verifyBankNo(bank_card) && !no) || verifyAlipay(no)) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('信息格式输入错误，请重新输入'))
  }

  const [account, setAccount] = useState<AccountData>({} as AccountData)

  useEffect(() => {
    getBankInfo().then((e = {} as AccountData) => {
      setAccount(e);
      setBankCard(e.bank_card);
      setBankName(e.bank_name);
      setBankBranchName(e.bank_branch_name);
      setBankAounterPhone(e.bank_counter_phone);
      setAlipayAccount(e.alipay_account);
      form.setFieldsValue(e)
    })
  }, [form])

  const onFocus = (e: any) => {
    e.target && inputScrollView(e.target as HTMLElement)
  }

  const [bank_card, setBankCard] = useState<string>('')
  const [bank_name, setBankName] = useState<string>('')
  const [bank_branch_name, setBankBranchName] = useState<string>('')
  const [bank_counter_phone, setBankAounterPhone] = useState<string>('')
  const [alipay_account, setAlipayAccount] = useState<string>('')

  const onChange = (value: string, key: string) => {
    if (key === 'bank_card') setBankCard(value)
    else if (key === 'bank_name') setBankName(value)
    else if (key === 'bank_branch_name') setBankBranchName(value)
    else if (key === 'bank_counter_phone') setBankAounterPhone(value)
    else if (key === 'alipay_account') setAlipayAccount(value)
  }

  const leftButton = () => {
    window.location.replace(document.referrer)
  }

  return (
    <div className="my-info-page">
      <NavBar title="我的信息" leftButton={leftButton} />

      <section className="info-page">
        <div className="card my-info">
          <h3>银行信息</h3>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={account}
            footer={
              <Button type='submit' disabled={!bank_card && !alipay_account} color='primary'>
                提交
              </Button>
            }
          >
            <Form.Item
              label='* 银行卡号'
              name='bank_card'
              rules={[{ validator: checkBankNo }]}
            >
              <Input placeholder='请输入银行卡号' onChange={e => onChange(e, 'bank_card')} />
            </Form.Item>

            <Form.Item name='bank_name' label='* 开户行' rules={[{ validator: checkBankName }]}>
              <Input placeholder='请输入开户行' onChange={e => onChange(e, 'bank_name')} />
            </Form.Item>

            <Form.Item name='bank_branch_name' label='* 开户支行' rules={[{ validator: checkBankBranchName }]}>
              <Input placeholder='请输入开户支行' onChange={e => onChange(e, 'bank_branch_name')} />
            </Form.Item>

            <Form.Item name='bank_counter_phone' label='* 银行卡预留手机号' rules={[{ validator: checkMobile }]}>
              <Input placeholder='请输入银行预留手机号' type='number' onChange={e => onChange(e, 'bank_counter_phone')} />
            </Form.Item>

            <p>*银行卡持卡人应与账号实名认证信息一致</p>

            <h3>支付宝</h3>

            <Form.Item
              label='支付宝账号'
              name='alipay_account'
              rules={[{ validator: checkAlipay }]}
            >
              <Input placeholder='请输入支付宝账号' onChange={e => onChange(e, 'alipay_account')} onFocus={onFocus} />
            </Form.Item>

            <p>*支付宝实名应与账号实名信息一致，格式为手机号或邮箱号</p>
          </Form>
        </div>
      </section>
    </div>
  );
}

export default MyInfo