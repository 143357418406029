import { produce } from "immer";

const userState = {
  signPlatform: {},
};

interface setUserInfoAction {
  type?: string;
  signPlatform?: Object;
}

interface draftStateReduer {
  signPlatform?: Object;
}

export const sign = (state = userState, action: setUserInfoAction) =>
  produce(state, (draftState: draftStateReduer) => {
    switch (action.type) {
      case "SET_PLAT_FORM":
        draftState.signPlatform = action.signPlatform;
        break;
      default:
        return draftState;
    }
  });
