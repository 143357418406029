import React, { Component } from "react";
import './bottomList.less'
import { Image } from 'antd-mobile';
import no1 from '@/image/invite/top_1.png'
import no2 from '@/image/invite/top_2.png'
import no3 from '@/image/invite/top_3.png'
import mascot from '@/image/app/mascot.png'
import { MyRankData } from '@/interface/invite'
import { InfiniteScroll } from 'antd-mobile'
import { ToggleCopy } from '@/utils/common'

interface Props {
  front?: string;
  behind?: string;
  rankList: Array<any>;
  myRank: MyRankData;
  visible: number;
  hasMore: boolean;
  loadMore: Function;
  tab: number
}

interface State {}

export default class UserDrawer extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  useToggleCopy(id:string) {
    ToggleCopy(id)
  }

  render() {
    return (
      <ul className="bottom-list">
        {
          this.props.rankList?.length?
          // this.props.rankList.map((item,index) => ())
          <div className="bottom-top">
            <div className="bottom-content">
              {
                this.props.rankList.map((item,index) => (
                  <li className="liItem" key={index}>
                    <div className="itemLeft">
                      {item.rank&&<>
                        {
                          item.rank === 1 || item.rank === 2 || item.rank === 3 ?  <Image src={item.rank === 1 ? no1 : item.rank === 2 ? no2 :  no3 } fit='contain' width={'33px'} height={'33px'}/> : <div className="itemRank">{item.rank}</div>
                        }
                      </>}
                      <Image src={item.user_avatar||item.avatar||mascot} fit='contain' width={'37px'} height={'37px'} style={{borderRadius: 32,marginRight:10}}/>
                      <div className="itemName">{item.user_nickname}
                        {item.rank ? <div></div> : <div style={{fontFamily: 'Arial'}}>ID:{item.user_id} <span style={{fontSize:8,background:' rgba(245,51,90,0.1)',borderRadius:'3px 3px 3px 3px',border:'1px solid #F5335A'}} onClick={this.useToggleCopy.bind(this, item.user_id)}>复制</span> </div>}
                      </div>
                    </div>
                    { item.rank ? <div className="itemRight">
                      <div style={{textAlign:'center', fontSize:17, fontFamily:'Arial'}}>{item.activity_rank_gap}</div>
                      { item.rank === 1 ? <div style={{fontFamily:'Arial'}}>NO.1</div> : <div className="distanceTop" style={{fontSize:11, color: '#f09f91'}}>距上一名</div>}
                    </div> : <div style={{color: '#f5c1b8',fontSize:12}}>{item.invite_time}</div>}
                  </li>
                ))
              }

              {
                this.props.tab === 2 ?
                <InfiniteScroll loadMore={this.props.loadMore()} hasMore={this.props.hasMore} /> : ''
              }
            </div>
          </div>
          :<div className="bottom-top no-data" style={{fontSize:12,color:'rgba(0,0,0,0.6)',textAlign:'center'}}>暂无数据</div>
        }
      
        {
          this.props.visible===1&&this.props.tab === 1 ? <div className="footer">
          <li className="liItem">
            <div className="itemLeft">
              <>
                {
                  this.props.myRank.rank === 1 || this.props.myRank.rank === 2 || this.props.myRank.rank === 3 ?  <Image src={this.props.myRank.rank === 1 ? no1 : this.props.myRank.rank === 2 ? no2 :  no3 } fit='contain' width={'33px'} height={'33px'} style={{width: 38}}/> : this.props.myRank.rank === 0? <div style={{color:'gray',fontSize:12,width:38}}>未上榜</div>:<div className="itemRank">{this.props.myRank.rank}</div>
                }
              </>
              {/* <Image src={no1} fit='contain' width={'33px'} height={'33px'}/> */}
              <Image src={this.props.myRank.user_avatar||mascot} fit='contain' width={'37px'} height={'37px'} style={{borderRadius: 32, margin:8}}/>
              <div className="itemName">{this.props.myRank.user_nickname}</div>
            </div>
            <div className="itemRight">
              {
                this.props.myRank.rank === 1?
                <div>NO.1</div>:
                <>
                  <div style={{fontFamily:'Arial'}}>{this.props.myRank.activity_rank_gap}</div>
                  <div className="distanceTop">{this.props.myRank.rank ? '距上一名' : '距上榜'}</div>
                </>
              }
            </div>
          </li>
        </div>: <div></div>
        }
      </ul>
    )
  }
}