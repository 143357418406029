import wx from "weixin-js-sdk";
// import { postWxPaySign } from '@/api/recharge'

const wxPay = {
  // 获取微信支付SDK的签名
  getWxPaySign(params: any) {
    wx.config({
      debug: false,
      appId: params.app_id,
      timestamp: params.time_stamp,
      nonceStr: params.nonce_str,
      signature: params.pay_sign,
      jsApiList: ["chooseWXPay", "closeWindow"],
      openTagList: ["wx-open-launch-app", "wx-open-launch-weapp"],
    });
    // return new Promise((resolve:any) => {
    //   // 发起请求获取签名
    //   postWxPaySign(params).then((data:any) => {
    //     if (data.code === 200) {
    //       // 配置微信JS SDK
    //       wx.config({
    //         debug: false,
    //         appId: 'wxc63f675eaa8f2a0c',
    //         timestamp: data.time_stamp,
    //         nonceStr: data.nonce_str,
    //         signature: data.signature,
    //         jsApiList: [
    //           'chooseWXPay'
    //         ]
    //       })
    //       // 配置完成后返回一个resolve
    //       wx.ready(() => {
    //         resolve('')
    //       })
    //     }
    //   })
    // })
  },
  // 发起微信支付
  wxPay(params: any) {
    return new Promise((resolve, reject) => {
      // 调用微信支付
      wx.chooseWXPay({
        timestamp: params.time_stamp,
        nonceStr: params.nonce_str,
        package: params.package,
        signType: params.sign_type,
        paySign: params.pay_sign,
        success: (res: any) => {
          // 支付成功时返回resolve
          resolve(res);
        },
        fail: (err: any) => {
          // 支付失败时返回reject
          reject(err);
        },
      });
    });
  },
  closeWindow() {
    wx.closeWindow();
  },
  wx,
};

export default wxPay