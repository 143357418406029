import React, { Component } from "react";
import "./sign-dialog.less";
import { Button } from 'antd-mobile'
import { PlatFormData } from '@/interface/sign'

interface Props {
  close: Function;
  confirm: Function;
  platform: PlatFormData;
}

interface State {
  confirmText: string;
}

export default class SignDIalog extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {
      confirmText: '继续'
    }
  }

  componentDidMount() {
    // this.props.close()
    if (this.state.confirmText === '继续') {
      let num:number = 5
      let timer:any = null
      this.setState({confirmText: `继续 ( ${num} )`})
      
      timer = setInterval(() => {
        if (num <=5 && num > 1) {
          num--
          this.setState({confirmText: `继续 ( ${num} )`})
        } else {
          clearInterval(timer)
          timer = null
          this.setState({confirmText: `继续`})
        }
      }, 1000)
    }
  }

  confirm() {
    this.props.confirm()
  }

  render() {
    return (
      <>
        <h3>签约平台选择提醒</h3>
        {
          this.props.platform.used_quota_tag ?
          <ul>
            <li>正在使用：{this.props.platform.used_quota_tag}</li>
            <li>正在使用的签约平台：{this.props.platform.used_platform_name}</li>
            <li>正在使用平台服务费率：{this.props.platform.used_service_rates}%</li>
          </ul> : ''
        }
        <ul>
          <li>当前选择为：{this.props.platform.change_quota_tag}</li>
          <li>当前选择签约平台：{this.props.platform.change_platform_name}</li>
          <li>当前选择平台服务费率：{this.props.platform.change_service_rates}%</li>
        </ul>
        <ul>
          <li>本年度切换大额推荐平台次数：{this.props.platform.change_large_num}</li>
          <li>本月度切换小额推荐平台次数：{this.props.platform.change_small_num}</li>
        </ul>
        <ul>
          <li className="notify">*若自然年首次选择了大额推荐，则无法在切换小额推荐</li>
          <li className="notify">*若自然年首次选择了小额推荐，则还有一次切换大额推荐</li>
          <li className="notify">*每个自然月首次未进行提现可进行小额渠道的切换</li>
        </ul>
        <footer>
          <Button onClick={this.props.close.bind(this)}>再想想</Button>
          <Button color='primary' disabled={this.state.confirmText !== '继续'} onClick={this.confirm.bind(this)}>
            {this.state.confirmText}
          </Button>
        </footer>
      </>
    );
  }
}
