import { useState } from 'react';
import { Image } from 'antd-mobile';
import IosPng from "@/image/Ios.png";
import AndriodPng from "@/image/Andriod.png";
import './app-qrcode.less'
import { store } from '@/store'

export const AppQrcode = () => {
  const baseVal = sessionStorage.getItem('AppQrcode')
  const appQrcode = baseVal ? JSON.parse(baseVal): ''

  const [android_qrcode_url, setAndroidQrcodeUrl] = useState('data:image/png;base64,' + appQrcode.android_qrcode_url)
  const [ios_qrcode_url, setIosQrcodeUrl] = useState('data:image/png;base64,' + appQrcode.ios_qrcode_url)

  const mouseEnter = () => {
    const qrcode:{[index:string]:any} = store.getState().qrcode.appQrcode;
    setAndroidQrcodeUrl('data:image/png;base64,' + qrcode.android_qrcode_url)
    setIosQrcodeUrl('data:image/png;base64,' + qrcode.ios_qrcode_url)
  }

  return (
    <section className="app-qrcode">
      <div>
        <span className="app-qrcode-btn" onMouseEnter={mouseEnter}>
          <Image src={IosPng} fit='contain' alt="" />
          <span>IOS下载</span>
        </span>
        <div className="img-box">
          <div className="img-box-bg">
            <img className="ios_url" src={ios_qrcode_url} alt="" />
          </div>
        </div>
      </div>
      <div>
        <span className="app-qrcode-btn" onMouseEnter={mouseEnter}>
          <Image src={AndriodPng} fit='contain' alt="" />
          <span>安卓下载</span>
        </span>
        <div className="img-box">
          <div className="img-box-bg">
            <img className="android_url" src={android_qrcode_url} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}