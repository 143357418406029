import { useState, useEffect } from 'react'
import './Record.less'
import NavBar from '@/component/NavBar/NavBar';
import { InfiniteScroll, List } from 'antd-mobile'
import { getWithdrawList } from '@/api/my-wallet'
import { filterMoney } from '@/utils/filter';
import { recordData } from '@/interface/my-wallet'
import { jumpPage } from '@/utils/common'
import noDataPng from '@/image/withdraw/no-record.png'

const Record = (props:any) => {
  const [data, setData] = useState<any[]>([])
  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    onLoad()
  }

  const statusList: { [key: string]: any } = {
    'init': {
      title: '已申请',
      type: 'process-tag'
    },
    'proceed': {
      title: '提现中',
      type: 'process-tag'
    },
    'success': {
      title: '提现成功',
      type: 'success-tag'
    },
    'fail': {
      title: '提现失败',
      type: 'fail-tag'
    }
  }

  const [current_page, setCurrentPage] = useState<number>(1)
  const [page_size] = useState<number>(20)
  // const [total, setTotal] = useState<number>(0)

  const onLoad = () => {
    getWithdrawList({
      current_page: current_page,
      page_size: page_size
    }).then(e => {
      if (e) {
        // setTotal(e.total)
        setData(val => [...val, ...e.list])
        setCurrentPage(e.current_page++)

        if (e.page_size*e.current_page >= e.total) {
          setCurrentPage(e.current_page)
          setHasMore(false)
        }
      }
    }).catch(() => {})
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
  }, [])

  const filterTitle = (item: recordData) => {
    let title = ''
    if (item.status === 'fail') {
      title = '转出失败-退回'
    } else {
      title = `U币转出-${item.settle_type === 'alipay' ? '支付宝' : '银行卡'}`
    }

    return title
  }

  const checkOrder = (e:any) => {
    jumpPage(`/order/${e.apply_number}`)
  }
  
  return (
    <div className='withdraw-record-page'>
      <NavBar title="提取记录" />

      <section className="withdraw-record">
        {
          data.length ?
          <>
            <List>
              {data.map((item, index) => (
                <List.Item key={index} onClick={() => checkOrder(item)} arrow={false}>
                  <header>
                    <span>{ filterTitle(item) }({item.settle_type === 'bankcard' ? '尾号' : ''}{item.withdraw_bank_card})</span>
                    <span>{ filterMoney(item.amount) }</span>
                  </header>
                  <footer>
                    <span>{ item.created_at }</span>
                    <span className={statusList[item.status].type}>{ statusList[item.status].title }</span>
                  </footer>
                </List.Item>
              ))}
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </> :
          <img src={noDataPng} alt="" />
        }
      </section>
    </div>
  )
}

export default Record