import { produce } from "immer";

const userState = {
  appQrcode: {},
};

interface setUserInfoAction {
  type?: string;
  appQrcode?: Object;
}

interface draftStateReduer {
  appQrcode?: Object;
}

export const qrcode = (state = userState, action: setUserInfoAction) =>
  produce(state, (draftState: draftStateReduer) => {
    switch (action.type) {
      case "SET_APP_QRCODE":
        draftState.appQrcode = action.appQrcode;
        break;
      default:
        return draftState;
    }
  });
