import { $get, $post } from "@/utils/request";
import { ActivityCode, RankList, DateList, MyRankList } from "@/interface/invite"

// 获取邀请码
export const postActivityDetail = (data: ActivityCode) => {
  return $post(`/activity/v1/activity/detail`, data).then((res: any) => res.data)
}

// 活动榜单
export const postActivityRankList = (data: RankList) => {
  return $post(`/activity/v1/activity/rank_list`, data).then((res: any) => res.data)
}

// 我的邀请
export const postActivityMyList = (params: MyRankList) => {
  return $get(`/activity/v1/activity/invite/my/list`, params).then((res: any) => res.data)
}
// 邀请榜单日期查询
export const getInviteDateList = (params: DateList) => {
  return $get(`/activity/v1/activity/invite/date/list`, params).then((res: any) => res.data)
}