import { Image } from 'antd-mobile';
import { useState, useEffect } from "react";
import './TopRoad.less'
import topImg from '@/image/TopRoad/top_img.png'
import copyBtn2 from '@/image/TopRoad/copy_btn2.png'
import topProcess from '@/image/TopRoad/top_process.png'
import inviteNumberBg from '@/image/TopRoad/invite_number_bg.png'
import BottomList from "./components/bottomList/bottomList"
import { getInviteMyList, postActivityDetail } from '@/api/toproad'
import award_png from "@/image/TopRoad/rules.png";
import { jumpPage, ToggleCopy } from '@/utils/common'

const TopRoad = (props:any) => {
  const [tab,setTab] = useState<number>(1)
  const [inviteNum, setInviteNum] = useState('')
  const [myRankList, setMyRankList] = useState<Array<any>>([])
  const [count,setCount] = useState(0)
  // const [tabTime, setTabTime]=useState('')
  const [current_page, setCurrentPage] = useState<number>(1)
  const [page_size] = useState<number>(30)

  const ToggleTabBar = (index: number) => {
    setTab(index)

  }
  const [hasMore, setHasMore] = useState<boolean>(true)
  const getActivityMyList = () => {
    hasMore && getInviteMyList({
      page: current_page,
      limit: page_size,
      activity_code: 'YQ002'
    }).then((e:any) => {
      if (!e.list || !hasMore) {
        setHasMore(false)
        return
      }
      let arr = []
      if (current_page === 1) {
        setMyRankList([])
        arr = e.list
      } else arr = [...myRankList, ...e.list]

      let num = current_page
      setCurrentPage(++num)
      setMyRankList(arr)
      setCount(e.count)
      // if (num*e.limit >= e.total) {
      //   setCurrentPage(num)
      // }
      setHasMore(arr.length !== e.count)
    })
  }

  const onLoad = () => {
    postActivityDetail({
      activity_code: 'YQ001'
    }).then(e => {
      setInviteNum(e.pretty_number)
    })

    getActivityMyList()
  }

  useEffect(() => {
    onLoad()

    const pageDom = document.querySelector('.top-road') as HTMLElement;
    
    const handleScroll = () => {
      const navBarDom = document.querySelector('.nav-bar') as HTMLElement;
      navBarDom.style.display = pageDom.scrollTop >= 50 ? 'none' : 'flex';
    }

    pageDom?.addEventListener('scroll', handleScroll);

    // 组件卸载时移除监听器
    return () => pageDom.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <section className='top-road'>
        <section className='top_img'>
          <Image src={topImg} fit='contain'  width={'100%'} />
          {/* 邀请码以及复制按钮 */}
          <div className='invitation-number'>
            <Image src={inviteNumberBg}></Image>
            <div className='my-number'>
              <div>我的邀请靓号</div>
              <div className='font-class'>{inviteNum}  &nbsp;<Image src={copyBtn2} fit='contain' width={'46px'} onClick={() => ToggleCopy(inviteNum)} /></div>
            </div>
          </div>
          <div className="process">
            <Image src={topProcess} fit='contain'  width={'100%'} />
          </div>
        </section>
        <div className="award_png">
          <img src={award_png} alt="" onClick={jumpPage.bind(this, '/top-road-reward')}/>
        </div>
        <div className='bottom'>
          {/* 邀请排行tab */}
          <div className="tab">
            <div className={ tab ===1?'rankActive':'rank'} onClick={() => ToggleTabBar(1)}>我的邀请({count}人)</div>
            <div className={ tab ===2?'myInviteActive':'myInvite'} onClick={() => ToggleTabBar(2)}>邀请奖励</div>
          </div>
          {/* 底部list */}
          <div className='bottom-list'>
            {
              tab === 1 ? 
              <BottomList rankList={myRankList} tab={1} hasMore={hasMore} loadMore={() => getActivityMyList()}/> :
              <BottomList tab={2} hasMore={false} loadMore={() => {}}/>
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default TopRoad