import { Image } from 'antd-mobile';
import './top-road-reward.less'
import bg_top from '@/image/TopRoad/bg_top.png'
import bg_bottom from '@/image/TopRoad/bg_bottom.png'

const RewardRules = (props:any) => {
  return (
    <section className='reward-rules1'>
      <Image src={bg_top} fit='contain'/>
      <div className="content">
        <div className="conten-item">
          <p>1、活动时间：2024-10-28 00:00:00开始</p>
        </div>
        <div className="conten-item">
          <p>2、邀请的新用户在搜索界面搜索靓号，并成功展示该靓号的搜索结果时自动绑定成功；绑定时该用户的注册时间需≤24小时，超过后绑定无效；绑定成功后该用户不可再与其他人绑定；充值的钻石数累计到邀请者的名下，绑定关系长期有效；</p>
        </div>
        <div className="conten-item">
          <p>3、平台顶流之路活动只针对CP引流活动邀请的新用户支持绑定，若发现有绑定除CP转化以外的用户则回收引流靓号取消参加顶流之路资格及扣除当周所有奖励；
          </p>
        </div>
        <div className="conten-item">
          <div>
          4、活动仅针对在当周存在CP抗走记录并且实现了转化的主播进行结算，若当周没有CP抗走记录则不给予结算奖励，当周奖励按自然周进行统计，截止日期为当周周日23:59:59；
          </div>
        </div>
        <div className="conten-item">
          <div>
          5、邀请CP引流单个有效新用户充值周奖励：
          <div>10000~100000钻石奖励2000U币、<br />100100~500000钻石奖励20000U币、<br />500100钻石及以上奖励50000U币，<br />U币奖励在每个周期结束后将自动发放；</div>
          </div>
        </div>
        <div className="conten-item">
          <div>
          6、本平台严厉打击买卖虚拟物品等行为，用户的任何私下交易行为，需自行承担不利后果。本平台坚决打击一切网络赌博、变相网络赌博行为；
          </div>
        </div>
        <div className="conten-item">
          <div className="top">
            非平台官方渠道充值、代充、买卖虚拟物品、通过活动礼物实施的任何影响互动公平性、利用产品BUG等不正当手段参与活动等行为均属禁止行为，一经发现平台将做封号处理并保留一切追究法律责任的权利。
          </div>
        </div>
      </div>
      <Image src={bg_bottom} fit='contain' className='bg_bottom'/>
      <div className="bottom_title">
        *本活动最终解释权归平台所有
      </div>
    </section>
  )
}

export default RewardRules