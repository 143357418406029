import { $get, $post } from "@/utils/request";
import { TopRoad, ActivityCode } from "@/interface/toproad"

// 获取邀请码
export const postActivityDetail = (data: ActivityCode) => {
  return $post(`/activity/v1/activity/detail`, data).then((res: any) => res.data)
}

// 我的邀请
export const getInviteMyList = (params: TopRoad) => {
  return $get(`/activity/v1/activity/invite/my/list`, params).then((res: any) => res.data)
}