import { useState } from "react";
import { Image } from 'antd-mobile';
import './reward-rules.less'
import weekRewardWall from '@/image/invite/reward-rules/week_reward_wall.png'
import monthRewardWall from '@/image/invite/reward-rules/month_reward_wall.png'
import ruleDescription from '@/image/invite/reward-rules/rule_description.png'

const RewardRules = (props:any) => {
  const [currentNav, setCurrentNav] = useState<number>(1)

  return (
    <section className='reward-rules'>
      <nav className='btns'>
        <span className={currentNav === 1 ? 'active' : ''} onClick={() => setCurrentNav(1)}>周榜奖励</span>
        <span className={currentNav === 2 ? 'active' : ''} onClick={() => setCurrentNav(2)}>月榜奖励</span>
        <span className={currentNav === 3 ? 'active' : ''} onClick={() => setCurrentNav(3)}>规则说明</span>
      </nav>

      <div className="content">
        <Image src={currentNav === 1 ? weekRewardWall : currentNav === 2 ? monthRewardWall : ruleDescription} fit='contain' />
      </div>
    </section>
  )
}

export default RewardRules