import { Image } from 'antd-mobile';
import { useState, useEffect } from "react";
import './Invite.less'
import topImg from '@/image/invite/top_img.png'
import arrow from '@/image/invite/arrow.png'
import BottomList from "./components/bottomList/bottomList"
import copy from '@/image/invite/copy.png'
import { postActivityDetail, postActivityRankList, getInviteDateList, postActivityMyList } from '@/api/invite'
import { MyRankData, TimeList } from '@/interface/invite'
import award_png from "@/image/invite/award_png.png";
import { jumpPage, ToggleCopy } from '@/utils/common'

const Invite = (props:any) => {
  const [tab,setTab] = useState<number>(1)
  const [tabType,setTabType] = useState<string>('YQ-WK')
  const [timeItem,setTimeItem] = useState<number>(0)
  const [showTime,setShowTime] = useState<string>('')
  const [inviteNum, setInviteNum] = useState('')
  const [rankList, setRankList] = useState<Array<any>>([])
  const [myRankList, setMyRankList] = useState<Array<any>>([])
  const [visible, setVisible] = useState(0)
  const [count,setCount] = useState(0)
  // const [tabTime, setTabTime]=useState('')
  const [myRank, setMyRank] = useState<MyRankData>({
    user_id: 0,
    user_nickname: "",
    user_avatar: "",
    rank: 0,
    activity_rank_gap: "",
    activity_value: 0
  })
  const [dateList, setDateList] = useState([])
  const [date, setDate] = useState<Array<any>>([])
  const [current_page, setCurrentPage] = useState<number>(1)
  const [page_size] = useState<number>(30)

  // const [isShowTime,showArrowTime] = useState<boolean>(false)
  const ToggleTabBar = (index: number) => {
    setTab(index)
    setShowImage(false)

    // index === 1 && getInviteList(tabType,tabTime)
    // index === 2 && getActivityMyList()
  }

  const getInviteList = (rank_code: string, activity_date:string) => {
    postActivityRankList({
      activity_code: 'YQ001',
      rank_code,
      activity_date
    }).then(e => {
      setRankList(e.rank_list || [])
      setMyRank(e.self)
      setVisible(e.visable)
    })
  }

  const [hasMore, setHasMore] = useState<boolean>(true)
  const getActivityMyList = () => {
    hasMore && postActivityMyList({
      page: current_page,
      limit: page_size,
      activity_code: 'YQ001'
    }).then((e:any) => {
      if (!e.list || !hasMore) {
        setHasMore(false)
        return
      }
      let arr = []
      if (current_page === 1) {
        setMyRankList([])
        arr = e.list
      } else arr = [...myRankList, ...e.list]
      // if (arr.length === e.count) return

      let num = current_page
      setCurrentPage(++num)
      setMyRankList(arr)
      setCount(e.count)
      // if (num*e.limit >= e.total) {
      //   setCurrentPage(num)
      // }
      setHasMore(arr.length !== e.count)
    })
  }

  const ToggleType = (rank_code: string) => {
    setTabType(rank_code)
    getDateList(rank_code)
    // const time = rank_code==='YQ-WK' ? date[0].invite_date : date[0].month
    // console.log('33333333',date, date[0].invite_date,date[0].month)
    // setShowTime(time)
    setShowImage(false)

    ToggleTime(0, )
  }

  const getDateList = (rank_code:string) => {
    getInviteDateList({
      activity_code: 'YQ001',
      rank_code
    }).then(e => {
      setDate(e)
      const list = rank_code==='YQ-WK'?e.map((item:TimeList) => {
        return item.invite_date
      }):e.map((item:TimeList) => {
        return item.month
      })
      const time = rank_code==='YQ-WK' ? e[0]?.invite_date : e[0]?.month
      setShowTime(time)
      setDateList(list)
      // setTabTime(e[0].invite_date)
      getInviteList(rank_code ,e[0].invite_date)

    })
  }

  const ToggleTime = (index: number, item?:string) => {
    setTimeItem(index)

    if (item) {
      setShowTime(item)
      setShowImage(false)
      const result = date.find((element:TimeList) => {
        return element.invite_date === item || element.month === Number(item)
      }) || {invite_date: '', month: 0}
      getInviteList(tabType, result.invite_date)

    }
  }

  const [showImage, setShowImage] = useState(false);
  const toggleTime = () => {
    setShowImage(!showImage);
    // if(showTime){
    //   this.showImage=false
    // }
  };

  const onLoad = () => {
    postActivityDetail({
      activity_code: 'YQ001'
    }).then(e => {
      setInviteNum(e.pretty_number)
    })
    
    getDateList(tabType)
    getActivityMyList()
  }


  
  useEffect(() => {
    onLoad()

    const pageDom = document.querySelector('.invite-page') as HTMLElement;
    
    const handleScroll = () => {
      const navBarDom = document.querySelector('.nav-bar') as HTMLElement;
      navBarDom.style.display = pageDom.scrollTop >= 50 ? 'none' : 'flex';
    }

    pageDom?.addEventListener('scroll', handleScroll);

    // 组件卸载时移除监听器
    return () => pageDom.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <section className='invite-page'>
        {/* top */}
        <section className='top_img'>
          <Image src={topImg} fit='contain'  width={'100%'} />
          {/* 邀请码以及复制按钮 */}
          <div className='invitation-number'>
            <div className='font-class'>{inviteNum}</div>
            <Image src={copy} fit='contain'  width={'31px'} height={'17px'} onClick={() => ToggleCopy(inviteNum)} style={{ margin: '0 auto' }}/>
          </div>
        </section>
        <div className="award_png">
          <img src={award_png} alt="" onClick={jumpPage.bind(this, '/invite/reward-rules')}/>
        </div>
        <div className='bottom'>
          {/* 邀请排行tab */}
          <div className="tab">
            <div className={ tab ===1?'rankActive':'rank'} onClick={() => ToggleTabBar(1)}>邀请排行</div>
            <div className={ tab ===2?'myInviteActive':'myInvite'} onClick={() => ToggleTabBar(2)}>我的邀请({count}人)</div>
          </div>
          {/* 周月榜tab */}
          {tab===1 ? <div className="tab-time-type">
            <div className="tab-type">
              <div className={ tabType ==='YQ-WK'?'tab-type-active':'week'} onClick={() => ToggleType('YQ-WK')}>周榜</div>
              <div className={ tabType ==='YQ-MH'?'tab-type-active':'week'} onClick={() => ToggleType('YQ-MH')}>月榜</div>
            </div>
            <div className="tab-time">
              {/* 2024.08.05-2024.08.11     dateList*/}
              <div className='time' onClick={() => toggleTime()}>{showTime?showTime:dateList[0]}{tabType==='YQ-MH'? <span>月</span>: <span></span>}</div>
              <Image src={arrow} fit='contain'  width={'21px'} height={'21px'} onClick={() => toggleTime()}/>
              { showImage && <div className='arrow-time'>
                {
                  dateList?.length ?
                    dateList.map((item, index) => (
                      <div className={timeItem ===index ? 'time-item-ative': 'time-item'} onClick={() => ToggleTime(index, item)}>{item}{tabType==='YQ-MH'? <span>月</span>: <span></span>}</div>
                    ))
                  : <div></div>
                }
              </div>}
            </div>
          </div>: <div></div>}
          {/* 底部list */}
          <div className='bottom-list'>
            {
              tab === 1 ? 
              <BottomList rankList={rankList} tab={1} myRank={myRank} visible={visible} hasMore={hasMore} loadMore={() => getActivityMyList()}/> :
              <BottomList rankList={myRankList} tab={2} myRank={myRank} visible={visible} hasMore={hasMore} loadMore={() => getActivityMyList()}/>
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default Invite