import { useState, useEffect } from "react";
import './TotalTopUp.less'
import { getAggreLevelList, postGiftGet } from '@/api/recharge'
import { getActivityList } from '@/api/activity'
import { AggreLevelData, DescInfoData } from '@/interface/recharge';
import { jumpPage } from "@/utils/common";
// 组件
import { Toast } from 'antd-mobile'
import NavBar from '@/component/NavBar/NavBar';
import RechargeCard from '@/component/Card/RechargeCard/recharge-card';
// 图片
import QuestionMarkPng from "@/image/recharge/question-mark.png";
import RechargeBgPng from "@/image/recharge/recharge-bg.png";
import ActiveAllBtnPng from "@/image/recharge/active-all-btn.png";
import ActiveLeftBtnPng from "@/image/recharge/active-left-btn.png";
import ActiveRightBtnPng from "@/image/recharge/active-right-btn.png";
import RhombusPng from "@/image/recharge/rhombus.png";
import InactiveLeftBtnPng from "@/image/recharge/inactive-left-btn.png";
import InactiveRightBtnPng from "@/image/recharge/inactive-right-btn.png";
import RechargeBtnPng from "@/image/recharge/recharge-btn.png";
import ImmediateClaimPng from "@/image/recharge/immediate-claim-btn.png";
import FireworksPng from "@/image/recharge/fireworks.png"

const TotalTopUp = () => {
  const rightButton = <img className='question-mark-img' src={QuestionMarkPng} alt="" />

  const [tab, setTab] = useState<number>(0)
  const [activityList, setActivityList] = useState<any[]>([{}])
  const [aggreLevelList, setAggreLevelList] = useState<any[]>([{
    rewardList: [{}],
    descInfo: {},
  }])
  const [descInfo, setDescInfo] = useState<DescInfoData>({
    now_aggre_level: 0,
    already_recharge: 0,
    again_recharge: 0,
    cost: 0,
    gift_bag: '',
  })
  const [activeTab, setActiveTab] = useState<number>(0)
  // const [typeList, setTypeList] = useState<any[]>([{}])
  const [levelListItem, setLevelListItem] = useState<any>({
    rewardList: [{}],
    descInfo: {},
  })
  const [get_state, setGetState] = useState<number>(0) //领取状态 0-查看 1-待领取 2-已领取

  const buttonClick = () => {
    window.location.href = '/top-up/explain'
  }

  const ToggleNaviBar = (index: number) => {
    setTab(index)
    const obj = {
      activity_code: activityList[index].activity_code,
      aggre_type: activityList[index].subclass_type,
    }

    getPageData(obj, index)
  }

  const selectTab = (index: number) => {
    setActiveTab(index)
    setLevelListItem(aggreLevelList[index])

    const rewardList = aggreLevelList[index].rewardList
    const get_state = rewardList ? rewardList[0].get_state : 0
    setGetState(get_state)
  }

  const getPageData = (obj: AggreLevelData, ind?: number) => {
    getAggreLevelList(obj).then(e => {
      if (!e.aggreLevelList) return
      let index = 0
      // e.aggreLevelList?.map((e2:any, i:number) => {
      //   return e2.aggre_level === e.now_aggre_level ? index = i : ''
      // })

      for(let i=0; i<e.aggreLevelList.length; i++) {
        if (e.aggreLevelList[i].aggre_level === e.now_aggre_level) {
          index = i
          break //中断循环，就没必要全部遍历一遍
        }
      }

      const defaultVal = {
        rewardList: [{}],
        descInfo: {},
      }

      setActiveTab(index)
      setAggreLevelList(e.aggreLevelList || [defaultVal])
      setLevelListItem(e.aggreLevelList[index] || defaultVal)
      setDescInfo(e.descInfo || {})
      if (!e.descInfo?.again_recharge) {
        setActiveTab(e.aggreLevelList.length - 1)  // 全部达到则选择最后一行
      }

      const rewardList = e.aggreLevelList[index].rewardList
      const get_state = rewardList ? rewardList[0].get_state : 0
      setGetState(get_state)
    }).catch(() => {
      setTab(ind === 1 ? 0 : 1)
    })
  }

  const handleClick = () => {
    if (get_state === 1 && tab === 0) {
      // 立即领取
      const obj = {
        activity_code: activityList[tab].activity_code,
        aggre_code: aggreLevelList[activeTab].aggre_code,
      }

      postGiftGet(obj).then(() => {
        Toast.show('领取成功！')

        // const obj = {
        //   activity_code: activityList[tab].activity_code,
        //   aggre_type: activityList[tab].subclass_type,
        // }
        // getPageData(obj)
        levelListItem.rewardList.map((e:any) => {
          return e.get_state = 2
        })
  
        setLevelListItem(levelListItem)
        selectTab(activeTab)
      })
    } else {
      // 去充值页
      jumpPage('/recharge')
    }
  }

  const filterMoney = (val: number) => {
    return val > 0 ? val : 0
  }

  useEffect(() => {
    getActivityList({ activity_type: 'LC' }).then(e => {
      setActivityList(e)
      const obj = {
        activity_code: e[0]?.activity_code,
        aggre_type: e[0]?.subclass_type,
      }

      setTab(obj.aggre_type === 'weekly' ? 1 : 0)
      getPageData(obj)
    }).catch(() => {})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const listDom = document.querySelector('.aggre-level-list') as HTMLElement;
    listDom.scrollLeft = activeTab * 87
    // listDom.scrollTo({
    //   left: activeTab * 87
    // })
  }, [activeTab])

  return (
    <div className="total-top-up">
      <NavBar title="充值享豪礼" rightButton={rightButton} buttonClick={buttonClick} />

      <section className="page-content">
        <img className='explain-img' src={RechargeBgPng} alt="" />

        <section>
          <nav>
            {
              activityList.length === 2 ?
              <>
                <div className='left-arrow-btn' onClick={() => ToggleNaviBar(0)}>
                  <img src={tab === 0 ? ActiveLeftBtnPng : InactiveLeftBtnPng} alt="" />
                  <span>{ activityList[0]?.title }</span>
                </div>
                <img src={RhombusPng} alt="" className='rhombus-btn' />
                <div className='right-arrow-btn' onClick={() => ToggleNaviBar(1)}>
                  <img src={tab === 1 ? ActiveRightBtnPng : InactiveRightBtnPng} alt="" />
                  <span>{ activityList[1]?.title }</span>
                </div>
              </> :
              <div className='all-arrow-btn'>
                <img src={ActiveAllBtnPng} alt="" />
                <span>{ activityList[0]?.title }</span>
              </div>
            }
          </nav>

          <ul className="aggre-level-list">
            {
              aggreLevelList.map((e:any, i:number) => {
                return <li className={activeTab === i ? 'active-btn' : ''} key={i} onClick={() => selectTab(i)}>{e.aggre_level}元</li>
              })
            }
          </ul>

          <p className='hint'>累充<span>{levelListItem.descInfo?.aggre_recharge}</span>元获得价值<span>{levelListItem.descInfo?.cost}</span>元{tab === 1 ? '福利礼包' : levelListItem.descInfo?.gift_bag}</p>

          <div className='card-list'>
            {
              levelListItem.rewardList?.map((item: any, index: number) => {
                return <RechargeCard url={item.reward_image} animatUrl={item.animation_url} title={item.reward_name} subTitle={item.reward_type} day={item.valid_day} isReceive={item.get_state === 2} dataContent={tab ? '已发放' : '已领取'} key={index} />
              })
            }
          </div>

          {
            !descInfo.again_recharge ?
            (
              tab === 0 ?
              <div className='recharge-hint2'>已解锁全部每日礼包，快去解锁每周礼包吧！</div> :
              <div className='recharge-hint2'>恭喜您本周已解锁全部奖励<img src={FireworksPng} alt="" /></div>
            ) :
            (
              get_state ?
              <div className='recharge-hint'><p>已获取该奖励，快去解锁下一个吧</p></div> :
              tab === 0 ?
              <div className='recharge-hint'>
                <p>今天已充值{descInfo.already_recharge}元</p>
                <p>再充值<span>{filterMoney(levelListItem.descInfo?.aggre_recharge - descInfo.already_recharge)}</span>元即可获得价值<span>{levelListItem.descInfo?.cost}</span>元福利礼包</p>
              </div> :
              <div className='recharge-hint'>
                <p>本周已充值{descInfo.already_recharge}元</p>
                <p>再充值<span>{filterMoney(levelListItem.descInfo?.aggre_recharge - descInfo.already_recharge)}</span>元即可获得该奖励</p>
              </div>
            )
          }

          <img src={get_state === 1 && tab === 0 ? ImmediateClaimPng : RechargeBtnPng} alt="" className='recharge-btn' onClick={() => handleClick()} />
        </section>
      </section>
    </div>
  )
}

export default TotalTopUp