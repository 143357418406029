import { useState, useEffect } from "react";
import "./OrderDetail.less";
import NavBar from '@/component/NavBar/NavBar';
import UCoinBigPng from '@/image/UCoin_big.png'
import { Steps } from 'antd-mobile'
import { CheckCircleFill } from 'antd-mobile-icons'
import { getWithdrawInfo } from '@/api/withdraw'
import { useParams } from 'react-router-dom';
import { OrderDetailData } from '@/interface/withdraw'
import { filterMoney } from '@/utils/filter';

const { Step } = Steps

const OrderDetail = () => {
  const [status, setStatus] = useState('success') // init proceed success/fail
  const [current, setCurrent] = useState(1)
  const [order, setOrder] = useState<OrderDetailData>({
    amount: 0,
    apply_number: '',
    created_at: '',
    handling_fee_amount: 0,
    real_amount: 0,
    reason: '',
    settle_type: '',
    status: '',
    withdraw_bank_card: '',
  })
  const { apply_number }:any = useParams();

  useEffect(() => {
    getWithdrawInfo({apply_number}).then(e => {
      setOrder(e)
      setStatus(e.status)

      if(e.status === 'init') setCurrent(0)
      else if(e.status === 'proceed') setCurrent(1)
      else if(['success', 'fail'].includes(e.status)) setCurrent(2)
    }).catch(() => {})
  }, [apply_number])

  return (
    <div className="order-detail-page">
      <NavBar title="订单详情" />

      <section className="order-detail">
        <div>
          <img src={UCoinBigPng} alt="" />
          <p>U币转出到-{ order.settle_type === 'alipay' ? '支付宝' : '银行卡' }({ order.withdraw_bank_card })</p>
          <p>{ filterMoney(order.amount) }</p>
        </div>
        <div>
          <aside>当前状态</aside>
          <Steps direction='vertical' current={current} style={{
            '--title-font-size': '14px',
            '--description-font-size': '12px',
            '--indicator-margin-right': '22px',
            '--icon-size': '36px',
          }}>
            <Step title='发起提现' />
            <Step title='平台处理中' description={status === 'fail' ? '审核未通过，U币退回中' : ''} />
            <Step title={current >= 2 ? (status === 'fail' ? '提现失败' : status === 'success' ? '提现成功' : '') : '' } description={status === 'fail' ? '已全额退回' : ''} icon={status === 'success' ? <CheckCircleFill /> : null} />
          </Steps>
        </div>
        <div>
          {
            status === 'fail' ?
            <>
              <p><span>退款原因：</span><span>{order.reason}</span></p>
              <p><span>退款金额：</span><span>{ filterMoney(order.amount) } U币</span></p>
            </> :
            <>
              <p><span>实际到账金额：</span><span>{ filterMoney(order.real_amount) } U币</span></p>
              <p><span>服务费：</span><span>{ filterMoney(order.handling_fee_amount) } U币</span></p>
            </>
          }
          <p><span>申请时间：</span><span>{ order.created_at }</span></p>
          <p><span>提现单号：</span><span>{ order.apply_number }</span></p>
        </div>
      </section>
    </div>
  );
}

export default OrderDetail