import React from "react";
// 引入路由导航栏
import { Navbar } from "./views/Navbar/Navbar";
// 引入routes组件
import routes from "./routes";
// 引入包管理工具
import { renderRoutes, RouteConfig } from "react-router-config";
import "./App.css";
import { useEffect } from "react";
import { useHistory } from 'react-router-dom'
// 引入connect连接组件
// import '@/utils/vconsole'

function App() {
  const history = useHistory()

  useEffect(() => {
    const page = routes.find((e:any) => e.path === history.location.pathname)
    const title = page?.meta?.title
    document.title = title ? `${title} - UU语音` : 'UU语音'
  }, [history])

  return (
    <div className="App">
      {/* 设置routes的类型为RouteConfig[]，否则报错 */}
      {renderRoutes(routes as RouteConfig[])}
      <Navbar />
    </div>
  );
}

//进行连接
export default App