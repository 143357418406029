import { $get } from "@/utils/request";
import { ActivityListData } from '@/interface/activity';

// 活动列表
export const getActivityList = (params: ActivityListData) => {
  return $get(`/core/v1/activity/list/h5`, params).then((res: any) => res.data)
}

// 礼物列表
export const getTypeList = () => {
  return $get(`/v1/props/type_list`).then((res: any) => res.data)
}
