import React, { Component } from "react";
import './iframe-box.less'
import { Button } from 'antd-mobile'

interface Props {
  src: string;
  title: string;
  cancel: Function;
  confirm: Function;
}

interface State {
  confirmText: string;
}

export default class IframeComponent extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {
      confirmText: '同意'
    }
  }

  componentDidMount() {
    // this.props.close()
    if (this.state.confirmText === '同意') {
      let num:number = 5
      let timer:any = null
      this.setState({confirmText: `同意 ( ${num} )`})
      
      timer = setInterval(() => {
        if (num <=5 && num > 1) {
          num--
          this.setState({confirmText: `同意 ( ${num} )`})
        } else {
          clearInterval(timer)
          timer = null
          this.setState({confirmText: `同意`})
        }
      }, 1000)
    }
  }

  render() {
    return (
      <div className="iframe-page">
        <iframe
          src={this.props.src}
          className="iframe-box"
          allowFullScreen
          title={this.props.title}
        />

        <footer>
          <Button onClick={this.props.cancel.bind(this)}>
            取消
          </Button>
          <Button color='primary' onClick={this.props.confirm.bind(this)} disabled={this.state.confirmText !== '同意'}>
            {this.state.confirmText}
          </Button>
        </footer>
      </div>
    )
  }
}
