import { useState, useRef, useEffect } from "react";
import "./Withdraw.less";
import NavBar from '@/component/NavBar/NavBar';
import { Form, Input, Button, Dialog } from 'antd-mobile'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'
import { RightOutline } from 'antd-mobile-icons'
import BankPng from '@/image/Bank.png'
import AlipayPng from '@/image/recharge/Alipay.png'
import { useParams } from 'react-router-dom';
import { getAccountInfo, postWithdrawCalc, postWithdrawApply } from '@/api/my-wallet'
import { CalcInfoData } from '@/interface/withdraw'
// import { connect } from "react-redux";
import { store } from '@/store'
import { jumpPage } from '@/utils/common'
import { useHistory } from 'react-router-dom'
import { filterMoney } from '@/utils/filter'

const Withdraw = () => {
  const history = useHistory()
  const [active, setActive] = useState(0)
  const handler = useRef<DialogShowHandler>()
  const { platform_number }:any = useParams();
  const [platform_info, setPlatformInfo] = useState({
    platform_number: '',//平台编码
    withdraw_channel: [''],//渠道编码 bankcard
    service_rates: 0,//服务费率
  })
  const [account, setAccount] = useState({
    amount: 0,//我的U币数量
    withdraw_num: 0,//今日剩余次数
    today_quota: 0,//今日可申请额度,当今日剩余次数等于0时不显示该字段
    month_quota: 0,//月剩余额度
    user_id: 0,
  })

  // 获取携带在本地的签约平台信息
  const sign:{[index:string]:any}= store.getState().sign.signPlatform;

  useEffect(() => {
    getAccountInfo(platform_number).then(e => {
      setPlatformInfo(e.platform_info)
      setAccount(e.account)
    }).catch(() => {})
  }, [platform_number])

  const buttonClick = () => {
    jumpPage('/withdraw-record')
  }

  const primaryVal:CalcInfoData = {
    amount: 0,//提现金额
    handling_fee_amount: 0,//手续费
    real_amount: 0,//实际到账
    withdraw_account: '',//提现账号(脱敏)
    withdraw_channel: '',//提现渠道
  }

  const [calcInfo, setCalcInfo] = useState<CalcInfoData>(primaryVal)

  const trigWithDrawCalc = (amount:number, i?:number) => {
    const index = (i !== undefined && i !== null) ? i : active;
    const data = {
      platform_number,
      amount,
      withdraw_channel: payMethod[index].value,
    }

    return postWithdrawCalc(data)
  }

  const onFinish = (values: any) => {
    trigWithDrawCalc(Number(values.quota)).then((e:any) => {
      setCalcInfo(e)
      confirmPay(values, e)
    })
  }

  const [payMethod] = useState([
    { name: '支付宝', value: 'alipay', url: AlipayPng },
    { name: '银行卡', value: 'bankcard', url: BankPng },
  ])

  const checkUCoin = (_: any, value: number) => {
    if (value >= 100 && value <= 5000000) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('单笔最小10,000，最大5,000,000'))
  }

  const filterChannelName = (name:string) => {
    return name === 'alipay' ? '支付宝' : '银行卡'
  }

  const confirmPay = (values: any, e: CalcInfoData) => {
    handler.current = Dialog.show({
      content: (
        <>
          <h3 className="dialog-title">确认提交</h3>
          <section>
            <p>提现方式：{filterChannelName(e.withdraw_channel)}</p>
            <p>账号：{e.withdraw_channel === 'alipay' ? e.withdraw_account : `尾号(${e.withdraw_account})`}</p>
            <p>提现金额：{filterMoney(e.amount)} U币</p>
            <p>实际到账：{filterMoney(e.real_amount)} U币</p>
            <p>服务费：{filterMoney(e.handling_fee_amount)} U币</p>
          </section>
        </>
      ),
      // bodyClassName: 'new-dialog',
      // closeOnAction: true,
      actions: [
        [
          {
            key: 'cancel',
            text: '取消',
            onClick: () => {
              handler.current?.close()
            },
          },
          {
            key: 'confirm',
            text: '确定',
            bold: true,
            onClick: () => {
              const data = {
                platform_number,
                amount: Number(values.quota),
                withdraw_channel: payMethod[active].value,
              }

              postWithdrawApply(data).then(e => {
                handler.current?.close()
                history.push({pathname: '/result', state: e})
                // jumpPage('/result')
              })
            },
          },
        ],
      ],
    })
  }

  const [amount, setAmount] = useState<number>(0)
  const onChange = (num:any) => {
    if (num > 0) {
      setAmount(Number(num))
  
      trigWithDrawCalc(Number(num)).then((e:any) => {
        setCalcInfo(e)
      })
    } else {
      setAmount(0)
      setCalcInfo(primaryVal)
    }
  }

  const selectwithDrawMethod = (index:number) => {
    setActive(index)

    amount && trigWithDrawCalc(amount, index).then((e:any) => {
      setCalcInfo(e)
    })
  }

  return (
    <div className="use-withdraw-page">
      <NavBar title={sign.title} rightButton="提取记录" buttonClick={buttonClick} />

      <section className="withdraw-page">
        <nav className="notify">通知：新增两个签约平台，云账户小额平台与云账户大额平台，小额平台转大额平台每年随时可进行切换一次。大额非自然年首次无法转小额。</nav>

        <div className="card my-info">
          <h3>
            <span>我的钱包</span>
            <button className="small-btn" onClick={() => jumpPage('/info')}>我的信息<RightOutline /></button>
          </h3>
          <p><span>我的余额：</span><span>{filterMoney(account.amount)} U币</span></p>
          <p><span>今日剩余次数：</span><span>{account.withdraw_num} 次</span></p>
          <p><span>月剩余额度：</span><span>{filterMoney(account.month_quota)} U币</span></p>
        </div>

        <div className="card">
          <h3>输入申请额度</h3>
          <Form
            onFinish={onFinish}
            footer={
              <Button block type='submit' color='primary' size='large' disabled={!calcInfo.real_amount}>
                立即提交
              </Button>
            }
          >
            <Form.Item
              name='quota'
              rules={[{ validator: checkUCoin }]}
            >
              <div className="u-coin-line">
                <span>U币：</span>
                <Input onChange={onChange} placeholder='单笔最小10,000，最大5,000,000' type="number" />
              </div>
            </Form.Item>
            <p>实际到账：{filterMoney(calcInfo.real_amount)} U币</p>
            <Form.Item
              rules={[{ required: true, message: '请选择提取方式' }]}
            >
              <h3>选择提取方式</h3>
              <section>
                {
                  payMethod.filter(e => platform_info.withdraw_channel.includes(e.value)).map((item, index) => (
                    <div className={active === index ? 'active-btn' : ''} key={index} onClick={() => selectwithDrawMethod(index)}>
                      <img src={item.url} alt="" />
                      <p>{item.name}</p>
                    </div>
                  ))
                }
              </section>
            </Form.Item>
          </Form>

          <ul>
            <li>1、u币提现服务费 {platform_info.service_rates}%</li>
            <li>2、需先提交银行信息并签约服务协议后提交</li>
            <li>3、提现将在1-3个工作日内到账</li>
            <li>4、每日仅可提现 1 次，单次限额 5.000.000，若审核失败u币提取次数将一并退回</li>
            <li>5、咨询签约及打款请在微信公众号联系客服</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Withdraw