import { user } from "./userInfo/reducer";
import { sign } from "./signPlatform/reducer";
import { qrcode } from "./appQrcode/reducer";
import {
  // applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// 持久化配置
const persistConfig = {
  key: "root",
  storage,
};
// 这里可以添加其它的reducer模块
const reducer = combineReducers({
  user,
  sign,
  qrcode,
});

// let store=createStore(reducer)
const persistedReducer = persistReducer(persistConfig, reducer);
let store = createStore(persistedReducer);
const persistor = persistStore(store);
export { store, persistor };
