import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import App from "./App";
import reportWebVitals from "@/reportWebVitals";
// 引入路由组件
import { BrowserRouter as Router } from "react-router-dom";
// 引入移动端自适应
import "lib-flexible";
//引入rootReducer组件
// import { Provider } from "react-redux";
// import store from "./store";
import "./index.less";

import hina from '@/utils/hina'
hina()

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

// document.documentElement.style.fontSize = 37.5 + 'px';
// window.addEventListener('resize', function() {
//   document.documentElement.style.fontSize = 37.5 + 'px';
// });

root.render(
  <Router>
    <App />
  </Router>
  // <React.StrictMode>
  //   {/* provider组件将所有的组件包裹起来，用绑定属性的形式绑定store到组件中 */}
  //   {/* <Provider store={store}> */}
  //     <Router>
  //       <App />
  //     </Router>
  //   {/* </Provider> */}
  // </React.StrictMode>
);

reportWebVitals();