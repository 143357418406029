import { $get, $post } from "@/utils/request";
import { WithDrawCalcData, PagesParams } from "@/interface/my-wallet"

// 获取用户的钻石余额
export const getBalance = () => {
  return $get(`/v1/user/assets`).then((res: any) => res.data)
}

// U币钱包
export const getAccountInfo = (platform_number: string) => {
  return $get(`/v1/user/account/info_v2`, {platform_number}).then((res: any) => res.data)
}

// 提现费率计算(提交)
export const postWithdrawCalc = (data: WithDrawCalcData) => {
  return $post(`/v1/user/withdraw/calculate_v2`, data).then((res: any) => res.data)
}

// 提现申请
export const postWithdrawApply = (data: WithDrawCalcData) => {
  return $post(`/v1/user/withdraw/apply_v2`, data).then((res: any) => res.data)
}

// 提现列表
export const getWithdrawList = (params: PagesParams) => {
  return $get(`/v1/user/withdraw/list`, params).then((res: any) => res.data)
}
