import React, { Component } from "react";
import { Image } from 'antd-mobile'
import SVGA from 'svgaplayerweb'
import './recharge-card.less'

interface Props {
  url: string;
  title: string;
  subTitle: string;
  isReceive: boolean;
  day: string | number;
  dataContent: string;
  animatUrl: string;
}

interface State {}

export default class UserDrawer extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {}
  }
  bofang() {
    if (!this.props.animatUrl) return

    var player = new SVGA.Player('#animatUrl');//创建实例
    var parser = new SVGA.Parser(); //是否兼容
    parser.load(this.props.animatUrl, function (videoItem) {//加载并回调
      player.loops = 0;//播放几遍
      player.setVideoItem(videoItem);
      player.startAnimation();//开始播放动画
      player.clearsAfterStop = true;
      // clear(): 清空动画画布。
      // startAnimation(): 开始播放动画。
      // pauseAnimation(): 暂停播放动画。
      // stopAnimation(reset)：停止动画播放，并可选择是否重置到初始状态。
      // stepToFrame(frame, andPlay): 跳转到指定帧进行播放，frame 是目标帧的索引，andPlay 表示是否继续播放。
      // on(event, callback): 添加事件监听器，常见事件包括 onFinished（动画播放完毕时触发）、onFrame（动画帧更新时触发）等。
    })
  }
  componentDidMount() {
    
  }
  componentDidUpdate(prevProps:any, prevState:any) {
    // 当myValue状态变化时执行的操作
    this.bofang()
  }
  
  render() {
    return (
      <div className="recharge-card">
        <section className={this.props.isReceive ? 'receive-card-box' : ''} data-content={this.props.dataContent}>
          {
            this.props.animatUrl ?
            <div id="animatUrl" style={{ width: '64px' }} /> :
            <Image src={this.props.url} width={64} height={64} style={{ borderRadius: 32 }} fit='cover' lazy />
          }

          <span className="sub-title">{this.props.subTitle}</span>
        </section>
        <p>{this.props.title}</p>
        <span>（{this.props.day}{this.props.title?.toString().includes('喇叭券') ? '张' : '天'}）</span>
      </div>
    )
  }
}
