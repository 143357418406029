// import { useState } from "react";
import './NavBar.less'
// import { useHistory } from 'react-router-dom'
import { Image } from 'antd-mobile';

const NavBar = (props:any) => {
  // const [history] = useState(useHistory())

  const handleClick = () => {
    window.history.back();
    // history.goBack()
  }

  return (
    <nav className="nav-bar-header">
      <div onClick={() => props.leftButton ? props.leftButton() : handleClick()}>
        <span className="back-arrow"></span>
      </div>
      <header>{props.title}</header>
      <div>
        <Image src={props?.imageUrl} fit='contain' alt="" width={17}/>
        <span onClick={props?.buttonClick}>{props.rightButton}</span>
      </div>
    </nav>
  );
}

export default NavBar