import React, { Component } from "react";
import { Image } from 'antd-mobile';
import './footer-module.less'
import home_7 from "@/image/home/home_7.png";
import home_phone_7 from "@/image/home/home_phone_7.png";
import uu_icon from "@/image/app/uu_icon.png";
import uu_title from "@/image/uu_title.png";
import { AppQrcode } from "@/component/AppQrcode/app-qrcode";
import { isMobile } from '@/utils/common'

interface Props {
  height: number;
}

interface State { }

export default class HeaderModule extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <section className="footer-module">
        <Image className='home_7' src={isMobile() ? home_phone_7 : home_7} width={'100%'} height={this.props.height} fit='cover' lazy />
        <AppQrcode />
        <ul>
          <li className="img_icon">
            <img src={uu_icon} alt="" />
            <img src={uu_title} alt="" />
          </li>
          <li>©2024 安徽悠悦软件有限公司</li>
          <li>公司地址：安徽省铜陵市义安区东联镇毛桥街道177-201</li>
          <li>APP备案号：皖ICP备2023027970号-2A</li>
          <li><span>皖网文（2024）1739-021号</span> <span>营业性演出许可证：340000120644</span> <span>广播电视节目制作许可证：皖 字第01276号</span></li>
          <li>违法和不良信息举报邮箱：kefu@love-uu.com </li>
          <li>网站备案号：<a target="_blank" href="https://beian.miit.gov.cn/" rel="noreferrer">皖ICP备2023027970号-1</a><span> ｜<a target="_blank" href="https://www.12377.cn/jbxzxq/zpljbxzxq.html" rel="noreferrer">电信诈骗举报专区</a></span></li>
        </ul>
      </section>
    );
  }
}