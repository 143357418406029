import { produce } from "immer";

const userState = {
  userInfo: {},
};

interface setUserInfoAction {
  type?: string;
  userInfo?: Object;
}

interface draftStateReduer {
  userInfo?: Object;
}

export const user = (state = userState, action: setUserInfoAction) =>
  produce(state, (draftState: draftStateReduer) => {
    switch (action.type) {
      case "SET_USER":
        draftState.userInfo = action.userInfo;
        break;
      default:
        return draftState;
    }
  });
