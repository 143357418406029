import '../hina-cloud-js-sdk/hina.min.js'

// 活动触发埋点
const useHina = () => {
  // 创建 SDK 全局对象
  const hina = window['hinaDataStatistic'];

  // 用配置对象初始化 SDK
  hina.init({
    serverUrl: 'https://higateway.haishuu.com/gather?project=uuEvent&token=Pk8S7yBi', // 配置采集上报地址URL（在线申请），查看如下【[采集上报地址获取] 链接
    showLog: true,
    sendType: 'ajax',
    autoTrackConfig: {
      // 是否开启自动点击采集, true表示开启，自动采集 H_WebClick 事件
      clickAutoTrack: true,
    // 是否开启页面停留采集, true表示开启，自动采集 H_WebStay 事件
      stayAutoTrack: true,
      // 是否开启页面浏览采集，auto 表示开启，singlePage 表示单页面开启，false 关闭，可配合手动开启使用
      // 若页面中有锚点设计，需要将该配置设为 false，否则触发锚点会多触发 H_pageview 事件
      pageviewAutoTrack: 'singlePage',
      //  是否开启页面离开采集，true 表示开启，具体配置查看插件集成
      pageLeaveAutoTrack: true
    },
  });

  // 将 SDK 实例赋给全局变量 hina，或者其他您指定的变量
 window["hina"] = hina;
}

export default useHina