import { useState } from "react";
import "./submit-result.less";
import NavBar from '@/component/NavBar/NavBar';
import { filterMoney } from '@/utils/filter';
import successPng from '@/image/withdraw/start.png'
import failPng from '@/image/withdraw/error.png'
import { jumpPage } from '@/utils/common'

const SubmitResult = (props:any) => {
  const { amount, handling_fee_amount, real_amount, withdraw_account, withdraw_channel } = props.location.state || {};
  const [isSuccess] = useState<boolean>(withdraw_account ? true : false)

  return (
    <div className="submit-result-page">
      <header>
        <NavBar title={isSuccess ? '提交成功' : '提交失败'} />

        {
          isSuccess ?
          <div className="submit-result">
            <img src={successPng} alt="" />
            <h3>提交成功，处理中</h3>
          </div> : 
          <div className="submit-result">
            <img src={failPng} alt="" />
            <h3>提交失败，请稍后重试</h3>
          </div>
        }
      </header>

      {
        isSuccess ?
        <div className="_content">
          <div className="column" />
          <section>
            <header>
              <p>提现金额</p>
              <h4>{ filterMoney(amount) } U币</h4>
            </header>
            <ul>
              <li>
                <span>充值渠道：</span>
                <span>{ withdraw_channel === 'alipay' ? '支付宝' : withdraw_channel === 'bankcard' ? '银行卡' : '' }</span>
              </li>
              <li>
                <span>充值账号：</span>
                <span>{withdraw_channel === 'bankcard' ? `尾号(${withdraw_account})` : withdraw_account}</span>
              </li>
              <li>
                <span>实际到账：</span>
                <span>{ filterMoney(real_amount) } U币</span>
              </li>
              <li>
                <span>服务费：</span>
                <span>{ filterMoney(handling_fee_amount) } U币</span>
              </li>
            </ul>
          </section>
        </div> : 
        <div className="reminder">
          {/* U币已退回余额，如有疑问可在微信公众号联系客服 */}
        </div>
      }

      <footer>
        <span className="back-btn" onClick={() => jumpPage('/wallet')}>
          返回钱包
        </span>
      </footer>
    </div>
  );
}

export default SubmitResult