import { useState, useEffect } from "react";
import "./MyWallet.less";
import UCoinPng from '@/image/UCoin_big.png'
import banner_1 from '@/image/banner/banner_1.png'
import banner_2 from '@/image/banner/banner_2.png'
import DiamondPng from '@/image/diamond_big.png'
import { getBalance } from '@/api/my-wallet'
import { jumpPage } from '@/utils/common'
import { store } from '@/store'

const MyWallet = () => {
  const [masonryCount, setMasonryCount] = useState<string>('')
  const [UCoin, setUCoin] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [userInfo] = useState<any>(store.getState().user.userInfo || {})

  useEffect(() => {
    getBalance().then((e:any) => {
      setMasonryCount(e.amount + e.binding_amount)
      setUCoin(e.free_amount)
      setRole(e.user_role)
    })
  }, [])

  return (
    <section className="my-wallet-page">
      {
        userInfo.invite_code &&
        <img src={banner_1} className="banner_1" alt="" onClick={() => jumpPage('/invite')} />
      }
       {
        userInfo.invite_code &&
        <img src={banner_2} className="banner_1" alt="" onClick={() => jumpPage('/top-road')} />
      }

      <div className="balance-box diamond-balance">
        <aside>
          <h3>钻石余额</h3>
          <div>
            <img src={DiamondPng} alt="" />
            <span className={masonryCount.toString().length > 7 ? 'ellipsis' : ''}>{masonryCount}</span>
          </div>
        </aside>
        <button className="small-btn" onClick={() => jumpPage('/recharge')}>充值</button>
      </div>

      {
        role && role !== 'user' ?
        <div className="balance-box u-coin-balance">
          <aside>
            <h3>U币余额</h3>
            <div>
              <img src={UCoinPng} alt="" />
              <span className={UCoin.toString().length > 7 ? 'ellipsis' : ''}>{UCoin}</span>
            </div>
          </aside>
          <button className="small-btn" onClick={() => jumpPage('/sign')}>提现</button>
        </div> : ''
      }
    </section>
  );
}

export default MyWallet