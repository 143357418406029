import { useState, useRef, useEffect } from "react";
import NavBar from '@/component/NavBar/NavBar';
import "./SigningPlatform.less";
import { RightOutline } from 'antd-mobile-icons'
import { filterMoney } from '@/utils/filter'
import { Button, Dialog, Toast } from 'antd-mobile'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'
import SignDIalog from '@/component/Dialog/signDialog/sign-dialog'
import { getPlatformList, postApplySign, postPlatformChange } from '@/api/sign';
import { getBankInfo } from '@/api/bank'
import { PlatFormData } from '@/interface/sign'
import { store } from '@/store'
import { setPlatform } from "@/store/signPlatform/action"
import IframeComponent from "@/component/Iframe/iframe-box"
import { jumpPage } from '@/utils/common'
import { AccountData } from '@/interface/my-info'

const SigningPlatform = () => {
  const [active, setActive] = useState<number | null>(null)
  const handler = useRef<DialogShowHandler>()
  const [account, setAccount] = useState<AccountData>({} as AccountData)

  const triGetPlatformList = () => {
    getPlatformList().then(e => {
      if (e) {
        setCardList(e)
        e.map((v:any,i:number) => v.is_checked && setActive(i))
      }
    })
  }

  useEffect(() => {
    triGetPlatformList()

    getBankInfo().then((e = {} as AccountData) => {
      setAccount(e);
    })
  }, [])

  const statusList: { [key: string]: any } = {
    'no': {
      title: '未签约',
      type: 'process-tag'
    },
    'fail': {
      title: '签约失败',
      type: 'process-tag'
    },
    'success': {
      title: '已签约',
      type: 'success-tag'
    },
    'init': {
      title: '签约中',
      type: 'fail-tag'
    }
  }

  const [cardList, setCardList] = useState([
    {
      quota_tag: '',
      support_tag: '',
      remainder_quota: '',
      service_rates: '',
      status: 'no',
      platform_number: '',
      title: '',
      is_checked: '',
    }
  ])

  const next = () => {
    if (active !== null && cardList[active]?.status === 'success') {
      const { platform_number } = cardList[active]
      
      // 存储信息
      const action = setPlatform(cardList[active])
      store.dispatch(action)

      postPlatformChange(platform_number).then((e:PlatFormData) => {
        if (e.is_alter) {//先判断是否弹窗,后判断是否提示
          confirmPay(e)
        } else if (e.is_toast) {//后判断是否提示
          Toast.show(e.toast_msg)
        } else {// (弹窗和提示都为0则直接跳转) 
          confirm()
        }
      })
    } else Toast.show('需要签约后才可以进行下一步')
  }

  const close = () => {
    handler.current?.close()
  }

  const confirm = () => {
    if (active !== null) {
      const platform_number = cardList[active].platform_number
      jumpPage('/withdraw/' + platform_number)
    }
  }

  const confirmPay = (e:PlatFormData) => {
    handler.current = Dialog.show({
      content: (
        <SignDIalog close={close} confirm={confirm} platform={e} />
      ),
      bodyClassName: 'new-dialog',
      // closeOnAction: false,
      // actions: [
      //   [
      //     {
      //       key: 'cancel',
      //       text: '再想想',
      //       onClick: () => {
      //         handler.current?.close()
      //       },
      //     },
      //     {
      //       key: 'confirm',
      //       text: '继续',
      //       bold: true,
      //       onClick: () => {
      //         console.log(handler.current)
      //         // jumpPage('/order')
      //         // handler.current?.close()
      //       },
      //     },
      //   ],
      // ],
    })
  }

  const [is_preview, setIsPreview] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('')

  const toSign = (item:any) => {
    if (item.preview_url) {
      setUrl(item.preview_url)
      setIsPreview(true)
    } else {
      toApplySign(item.platform_number)
    }
  }

  const toApplySign = (platform_number:string = '') => {
    if (active === null) return
    const platformStr = platform_number ? platform_number : cardList[active].platform_number
    postApplySign(platformStr).then(e => {
      if (e.url) jumpPage(e.url)

      if (!platform_number) {
        triGetPlatformList()
        cancelSign()
      }
    })
  }

  const cancelSign = () => {
    setIsPreview(false)
  }

  const confirmSign = () => {
    toApplySign()
  }

  return (
    <div className="sign-platform-page">
      <NavBar title="选择签约平台" />

      <section className="sign-platform">
        <div className="card my-info">
          <h3>
            <span>我的信息</span>
            <button className="small-btn" onClick={() => jumpPage('/info')}>{account.bank_card ? '更改' : '完善信息'}<RightOutline /></button>
          </h3>
          <p>
            <span>银行卡：</span>
            {
              account.bank_card ? 
              <span>尾号{account.bank_card.slice(-4)}</span> : 
              <span className="notify">待完善</span>
            }
          </p>
          <p>
            <span>支付宝：</span>
            {
              account.alipay_account_secret ? 
              <span>{account.alipay_account_secret || '--'}</span> : 
              <span className="notify">待完善</span>
            }
          </p>
        </div>

        {
          cardList.map((item, index) => 
            <div className={active === index ? 'active-card card' : 'card'} key={index} onClick={() => setActive(active => active = index)}>
              <div className='right-top-tag'>{item.quota_tag}</div>
              <h1>
                {item.title}<span>（{item.support_tag}）</span>
              </h1>
              <p>月剩余额度：{filterMoney(item.remainder_quota)} u币</p>
              <p>
                <span>服务费率：{item.service_rates}%</span>
                <span className={statusList[item.status].type} onClick={() => item.status !== 'success' ? toSign(item) : ''}>
                  {statusList[item.status].title}
                  {
                    item.status !== 'success' ? <RightOutline /> : ''
                  }
                </span>
                {/* <span className='success-tag'>已签约</span>
                <span className='fail-tag'>签约中</span> */}
              </p>
            </div>
          )
        }

        <p className="notify">
          特别注意:<br />
          1、所有用户在签约之前必须完善提现信息<br />
          2、云账户大额签约需要15个工作日内才能更新签约状态
        </p>

        <section className="protocol-list">
          <h3>注意事项：</h3>
          <p>您理解并确认，您正在选择使用本平台合作的第三方平台提供的U币提取服务。若您有异议，请您停止使用。U币提取服务由具备合法资质的第三方向您提供，需要您仔细阅读、同意并遵守第三方的协议、相关规则。如因第三方产品或服务产生的争议、损失或损害，需由您自行与第三方依据相关协议解决。</p>

          <h3>签约与变更介绍：</h3>
          <p>1、签约选择：签约不影响u币提取平台选择。</p>
          <p>2、关于大额解约方式：连续12个月未进行提现操作时，系统将视为您已放弃大额推荐平台，合约失效。具体大额解约流程以第三方平台协议为准。</p>
          <p>3、签约变更：当您选择使用小额推荐平台时，一年有一次提升为大额推荐平台的机会。当提升为大额推荐平台后一个自然年内，<span className="notify">无法退回小额推荐平台</span>。自然年内会持续使用大额推荐平台进行u币提取。自然年结束如因个人收入降低，需要切换回小额平台时。应在自然年内首次进行u币提取前进行选择小额推荐平台。小额平台每个自然月首次可进行变更切换。</p>
          <p>4、税率介绍：由于平台不一样u币提取的服务费不一样，请仔细阅读后谨慎选择。</p>
          <p>5、当您根据实名完成签约后，我们将同步您本平台实名相同账号内所有平台的签约信息，以及u币提取选择的平台方式，剩余额度等。</p>
          <p>6、您每个自然年选择提升大额推荐平台或自然年首次选择小额推荐平台时，我们都会进行弹窗二次确认。若您仍继续选择，我们将视为您已同意以上内容的介绍。</p>
        </section>

        {
          active !== null && cardList[active]?.status === 'init' ?
          <p className="notify">你的合约正在签约中，请耐心等待</p> : ''
        }

        <Button block color='primary' size='large' disabled={active === null || cardList[active]?.status === 'init'} onClick={next}>
          已选，下一步
        </Button>
      </section>

      {
        is_preview ? <IframeComponent src={url} title={active !== null ? cardList[active].title : ''} cancel={cancelSign} confirm={confirmSign}  /> : ''
      }
    </div>
  );
}

export default SigningPlatform