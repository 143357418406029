import React, { Component } from "react";
import { RightOutline, CloseOutline } from 'antd-mobile-icons'
import './user-drawer.less'
import titlePng from "@/image/title.png";
import { store } from '@/store'
import { jumpPage } from '@/utils/common'

interface Props {
  parentFunc: Function;
}

// interface UserInfoItem {
//   avatar: string;
//   user_nickname: string;
//   user_id: string;
// }

interface State {
  pageClass: string;
  userInfo: any;
}

export default class UserDrawer extends Component<Props, State> {
// const UserDrawer = () => {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {
      pageClass: 'user-drawer-page close-user-drawer-page',
      userInfo: store.getState().user.userInfo || {}
    }
  }
  componentDidMount() {
    // let ui = localStorage.getItem('userInfo')
    // ui = ui && ui !== 'undefined' ? JSON.parse(localStorage.getItem('userInfo') as string) : {}
  }
  componentDidUpdate(prevProps:any, prevState:any) {
    // 当myValue状态变化时执行的操作
    const userInfo = store.getState().user.userInfo
    if (userInfo && userInfo !== prevState.userInfo) {
      this.setState({
        userInfo
      })
    }
  }
  closePage() {
    this.setState({
      pageClass: 'user-drawer-page close-user-drawer-page'
    })
  }
  exit() {
    sessionStorage.clear()
    localStorage.clear()
    this.props.parentFunc()
    this.setState({
      pageClass: 'user-drawer-page close-user-drawer-page',
      userInfo: {}
    })
    jumpPage('/')
  }
  
  render() {
    return (
      <section className={this.state.pageClass}>
        <>
          <header>
            <img src={titlePng} alt="" />
            <CloseOutline onClick={this.closePage.bind(this)} />
          </header>

          <nav className="account-info">
            <aside>
              <img className="head-portrait" src={this.state.userInfo.avatar} alt="" />
              <div>
                <h3>{this.state.userInfo.user_nickname}</h3>
                <p>ID:{this.state.userInfo.user_id}</p>
              </div>
            </aside>
            <span onClick={this.exit.bind(this)}>退出</span>
          </nav>

          <ul className="cell-list">
            <li onClick={jumpPage.bind(this, '/')}><span>首页</span><RightOutline /></li>
            <li onClick={jumpPage.bind(this, '/wallet')}><span>我的钱包</span><RightOutline /></li>
            {
              this.state.userInfo.invite_code &&
              <li onClick={jumpPage.bind(this, '/invite')}><span>邀请有礼</span><RightOutline /></li>
            }
            {
              this.state.userInfo.invite_code &&
              <li onClick={jumpPage.bind(this, '/top-road')}><span>顶流之路</span><RightOutline /></li>
            }
            <li onClick={jumpPage.bind(this, '/about')}><span>关于我们</span><RightOutline /></li>
          </ul>
        </>
      </section>
    )
  }
}

// export default UserDrawer