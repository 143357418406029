import { $get, $post } from "@/utils/request";

// 获取平台列表
export const getPlatformList = () => {
  return $get(`/v1/sign/platform/list`).then((res: any) => res.data)
}

// 签约v2
export const postApplySign = (platform_number: string) => {
  return $post(`/v1/sign/apply_v2`, {platform_number}).then((res: any) => res.data)
}

// 切换平台
export const postPlatformChange = (change_platform_number: string) => {
  return $post(`/v1/sign/platform/change`, {change_platform_number}).then((res: any) => res.data)
}

// 小额签约
export const postSmallSign = (apply_number: string) => {
  return $post(`/v1/sign/small_sign`, {apply_number}).then((res: any) => res.data)
}
