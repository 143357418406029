import React, { Component } from "react";
import './bottomList.less'
import { Image,Toast } from 'antd-mobile';
import noData from '@/image/TopRoad/no-data.png'
// import copyBtn from '@/image/TopRoad/copy_btn.png'
import invalid from '@/image/TopRoad/invalid.png'
import award from '@/image/TopRoad/award.png'
import mascot from '@/image/app/mascot.png'
import { InfiniteScroll } from 'antd-mobile'
import { ToggleCopy } from '@/utils/common'

interface Props {
  front?: string;
  behind?: string;
  rankList?: Array<any>;
  hasMore: boolean;
  loadMore: Function;
  tab: number
}

interface State {}

export default class UserDrawer extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  useToggleCopy(id:string) {
    ToggleCopy(id)
  }

  ToastTip(user_type:string) {
    let content = ''
    if(user_type === 'register') {
      content = '注册奶'
    }else {
      content = '付费注册奶'
    }
    Toast.show({
      content: `用户为${content}，不能参与此活动，为无效邀请用户`
    })
  }

  render() {
    return (
      <ul className="bottom_list">
        <div className="bottom-top">
          <div className="bottom-content" style={{display: this.props.tab === 1 ? 'block' : 'none'}}>
              {
                this.props.rankList?.length?
                <div>
                    {
                      this.props.rankList.map((item,index) => (
                        <li className="liItem" key={index}>
                          <div className="itemLeft">
                            <Image src={item.user_avatar||item.avatar||mascot} fit='cover' className="userAvatar" style={{ borderRadius: 18,marginRight: 10 }}/>
                            <div className="itemName">
                              <div className="name">{item.user_nickname}</div>
                              <div className="id">ID:{item.user_id} <span onClick={this.useToggleCopy.bind(this, item.user_id)}>复制</span>
                            </div>
                          </div>
                          </div>
                          <div className="itemRight">
                            <div className="invalidImg">{item.user_type &&<Image src={invalid} width={'26px'} onClick={this.ToastTip.bind(this,item.user_type)}/> }</div>
                            <div className="inviteTime">{item.invite_time}</div>
                          </div>
                        </li>
                      ))
                    }
                    {
                      <InfiniteScroll loadMore={this.props.loadMore()} hasMore={this.props.hasMore} />
                    }

                </div>
               
                :
                <div className="no-data">
                  <Image src={noData} fit='contain' />
                  <div>暂无数据~</div>
                </div>
              }
          </div>
          <div className="bottom-award" style={{display: this.props.tab === 2 ? 'block' : 'none'}}>
              <Image src={award} fit='contain'  width={'100%'} />
              <div className="award-content">
                <p>举例：</p>
                <p>主播邀请了新用户A和B，</p>
                <p>新用户A周累计充值10000钻石，可获得2000U币；</p>
                <p>新用户B周累计充值510000钻石，可获得50000U币；</p>
                <p>那么周奖励共获得52000U币。</p>
              </div>
          </div>
        </div>
      </ul>
    )
  }
}