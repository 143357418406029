import { IAssetItem, PayItem } from "@/interface/recharge";
import AlipayPng from "@/image/recharge/Alipay.png";
import WeChat from "@/image/recharge/WeChat.png";

export const listOfAmounts: Array<IAssetItem> = [
  // {
  //     mount: 100,
  //     money: 1,
  // },
  {
    mount: 600,
    money: 6,
  },
  {
    mount: 1000,
    money: 10,
  },
  {
    mount: 3000,
    money: 30,
  },
  {
    mount: 5000,
    money: 50,
  },
  {
    mount: 10000,
    money: 100,
  },
  {
    mount: 20000,
    money: 200,
  },
  {
    mount: 50000,
    money: 500,
  },
  {
    mount: 100000,
    money: 1000,
  },
  {
    mount: 200000,
    money: 2000,
  },
  {
    mount: 500000,
    money: 5000,
  },
];

export const methodOfPay: Array<PayItem> = [
  {
    name: '支付宝',
    value: 'Alipay',
    imgUrl: AlipayPng,
  },
  {
    name: '微信',
    value: 'WeChat',
    imgUrl: WeChat,
  }
]