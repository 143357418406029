import { $get, $post } from "@/utils/request";
import { BankData } from "@/interface/bank";

// 获取银行卡和支付宝信息
export const getBankInfo = () => {
  return $get(`/v1/user/account/bank_info`).then((res: any) => res.data)
}

// 银行卡信息保存
export const postMobileCode = (data: BankData) => {
  return $post(`/v1/user/bank_info/save_v2`, data).then((res: any) => res.data)
}
