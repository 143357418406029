import { useState } from "react";
import { Image } from 'antd-mobile';
import './home.less'
import { store } from '@/store'
import { setQrcode } from "@/store/appQrcode/action"
import { getAppQrcode } from "@/api/app-qrcode";
import home_2 from "@/image/home/home_2.png"
import home_3 from "@/image/home/home_3.png"
import home_4 from "@/image/home/home_4.png"
import home_5 from "@/image/home/home_5.png"
import home_6 from "@/image/home/home_6.png"
import home_phone_2 from "@/image/home/home_phone_2.png"
import home_phone_3 from "@/image/home/home_phone_3.png"
import home_phone_4 from "@/image/home/home_phone_4.png"
import home_phone_5 from "@/image/home/home_phone_5.png"
import home_phone_6 from "@/image/home/home_phone_6.png"
import Arrow from "@/component/Arrow/arrow"
import celebratory_look_png from "@/image/home/celebratory-look.png"
import smile_face_love_png from "@/image/home/smile_face_love.png"
import microphone_png from "@/image/home/microphone.png"
import love_png from "@/image/home/love.png"
import Meme from "@/component/Meme/meme"
import HeaderModule from "@/component/HeaderModule/header-module"
import FooterModule from "@/component/FooterModule/footer-module"
import NavBtn from "@/views/NavBtn/NavBtn"
import { isMobile } from '@/utils/common'

const Home = () => {
  const [height] = useState<number>(window.innerHeight)

  // 存储信息
  getAppQrcode().then((res:any) => {
    const action = setQrcode(res)
    store.dispatch(action)
  })

  return (
    <>
      <section className="picture_wall">
        <HeaderModule height={height} />
        <div className='adm-image'>
          <Arrow />
          <Image src={isMobile() ? home_phone_2 : home_2} className='home_2' width={'100%'} height={height} fit='cover' lazy />
        </div>
        <div className='adm-image'>
          <Arrow />
          <Meme src={celebratory_look_png} width={isMobile() ? '130px' : '200px'} />
          <Image src={isMobile() ? home_phone_3 : home_3} className='home_3' width={'100%'} height={height} fit='cover' lazy />
        </div>
        <div className='adm-image'>
          <Arrow />
          <Meme src={smile_face_love_png} width={isMobile() ? '136px' : '206px'} />
          <Image src={isMobile() ? home_phone_4 : home_4} className='home_4' width={'100%'} height={height} fit='cover' lazy />
        </div>
        <div className='adm-image'>
          <Arrow />
          <Meme src={microphone_png} width={isMobile() ? '99px' : '169px'} />
          <Image src={isMobile() ? home_phone_5 : home_5} className='home_5' width={'100%'} height={height} fit='cover' lazy />
        </div>
        <div className='adm-image'>
          <Arrow />
          <Meme src={love_png} width={isMobile() ? '140px' : '200px'} />
          <Image src={isMobile() ? home_phone_6 : home_6} className='home_6' width={'100%'} height={height} fit='cover' lazy />
        </div>
        <FooterModule height={height} />
      </section>

      <NavBtn />
    </>
  );
}

export default Home