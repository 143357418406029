import React, { Component } from "react";
import { Image } from 'antd-mobile';
import './meme.less'

interface Props {
  src: string;
  width: string;
}

interface State {}

export default class UserDrawer extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <Image src={this.props.src} className='meme-png' width={this.props.width} lazy />
    )
  }
}