import { Image, Button } from 'antd-mobile';
import './404.less'
import errorPng from '@/image/404/404.png'
import { jumpPage } from '@/utils/common'

const errorPage = () => {
  return (
    <div className='error-page'>
      <section>
        <Image src={errorPng} fit='contain' lazy />

        <Button color='primary' onClick={() => jumpPage('/')}>
          回到首页
        </Button>
      </section>
    </div>
  );
}

export default errorPage