import React, { Component } from "react";
import { Toast } from 'antd-mobile';
import "./login-dialog.less";
import { postMobileCode } from '@/api/user';

export default class LoginDialog extends Component {
  refs: any = React.createRef()
  state = {
    mobile: '',
    code: '',
    verifyText: '发送验证码',
    time: 60,
    timer: null,
    pattern: (/^1[3-9]\d{9}$/),
    hintMobileText: '',
    hintCodeText: '',
  }
  triggerTimer() {
    this.setState({
      verifyText: this.state.time.toString() + 's',
      time: this.state.time-1, // 先减一次是为了让客户点击的时候就立即触发，有更好的体验
      timer: setInterval(() => {
        if (this.state.time <= 60 && this.state.time > 1) {
          this.setState({
            time: this.state.time-1,
            verifyText: this.state.time.toString() + 's'
          })
        } else {
          if (this.state.timer) clearInterval(this.state.timer)
          this.setState({
            time: 60,
            verifyText: '发送验证码',
            timer: null
          })
        }
      }, 1000)
    })
  }
  sendVerify() {
    if (this.verifyMobile() && this.state.time === 60) {
      postMobileCode({ mobile: this.state.mobile }).then(() => {
        this.triggerTimer()
        Toast.show({
          icon: 'success',
          content: '验证码发送成功！',
        })
      })
    }
  }
  verifyMobile() {
    if (!this.state.pattern.test(this.state.mobile)) {
      const hintMobileText = '请先输入11位数字的手机号！'
      this.setState({hintMobileText})
      return false
    } else {
      this.setState({hintMobileText: ''})
      return true
    }
  }
  verifyCode() {
    if (this.state.code.toString().length !== 6) {
      const hintCodeText = '请输入6位数的短信验证码!'
      this.setState({hintCodeText})
    } else {
      this.setState({hintCodeText: ''})
      return true
    }
  }
  verifyContent() {
    return this.verifyMobile() && this.verifyCode()
  }

  render() {
    return (
      <div className="login-dialog">
        <h1>登录</h1>
        <section>
          <p>
            <input onChange={e => {
              e.target.value = e.target.value.substring(0,11)
              this.setState({mobile: e.target.value})
            }} placeholder="请输入11位数字的手机号" name="mobile" />
          </p>
          {
            this.state.hintMobileText ? 
            <span className="hint-text">{this.state.hintMobileText}</span> : ''
          }
          <p className="special-line">
            <input onChange={e => {
              e.target.value = e.target.value.substring(0,6)
              this.setState({code: e.target.value})
            }} type="number" placeholder="请输入短信验证码" name="code" />
            <button className="sendVerify" disabled={this.refs.code && !this.refs.code} onClick={this.sendVerify.bind(this)}>{this.state.verifyText}</button>
          </p>
          {
            this.state.hintCodeText ?
            <span className="hint-text">{this.state.hintCodeText}</span> : ''
          }
        </section>
      </div>
    );
  }
}