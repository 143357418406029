// 返回银行卡尾号后4位
export const filterBankCardTailNum = (no: string) => {
  return no.slice(-4)
}

// 格式化银行卡号，每隔四位加一个空格
export const filterBankCard = (no: string) => {
  // return no.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, (rs,$1,$2,$3,$4) => $1 + " " + $2 + " " + $3 + " " + $4 + " ")
  // return no.replaceAll(" ", "").replaceAll(/(\d{4})/g, "$1 ").replace(/\s$/, '')
  return no.replace(/\s/g, '').replace(/(\d{4})/g, "$1 ").replace(/\s$/, '')
}

// 数字每隔三位加一个逗号
export const filterMoney = (money: string | number) => {
  return (money || 0).toString().replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, ',$1')
}