// import { useState, useRef, useEffect } from "react";
import './TopUpExplain.less'
import ActiveDescPng from "@/image/recharge/activity-description.png";
import NavBar from '@/component/NavBar/NavBar';

const TopUpExplain = () => {
  // useEffect(() => {
  //   // document.body.addEventListener('touchmove', function (e) {
  //   //   e.preventDefault() // 阻止默认的处理方式(阻止下拉滑动的效果)
  //   // }, { passive: false }) // passive 参数不能省略，用来兼容ios和android
  // }, [])

  return (
    <div className="top-up-explain">
      <NavBar title="活动说明" />

      <section className="page-content">
        <img className='explain-img' src={ActiveDescPng} alt="" />
      </section>
    </div>
  )
}

export default TopUpExplain