import { Image } from 'antd-mobile';
import './About.less'
import addressPng from '@/image/address.png'

const About = (props:any) => {

  return (
    <section className='about-page'>
      <h1>安徽省悠悦软件科技有限公司</h1>

      <p>软件介绍：UU语音，一个创新的语音平台，专注于通过声音增强沟通与分享。这里是你发现新声音、分享生活见闻和获取资讯的理想场所。</p>

      <p>公司宗旨：安徽省悠悦软件科技有限公司，旗下独立开发运营的语音软件平台“UU语音”，打造优质社交。公司以“用声音温暖这个世界”为使命、以“给全球年轻人一个崭新的交流平台”为愿景、坚定奉行“团结友爱、求真务实、求责于己、始终创业”的价值观，致力于让年轻人感受到声音温暖这个世界。</p>

      <p>
        公司地址：
        <span>安徽省铜陵市义安区东联镇毛桥街道177-201</span>
      </p>

      <p className='special'>邮箱: <a href="mailto:kefu@love-uu.com">kefu@love-uu.com</a></p>
      <p className='special'>工作日: 10:00-22:00</p>
      <p className='special'>
        线上留言请至微信公众号 "
        <b>UU语音app</b>
        " 联系在线客服
      </p>

      <Image src={addressPng} fit='contain' />
    </section>
  )
}

export default About