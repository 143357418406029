import React, { Component } from "react";
import { Image } from 'antd-mobile';
import './header-module.less'
import home_1 from "@/image/home/home_1.png";
import home_phone_1 from "@/image/home/home_phone_1.png"
import { AppQrcode } from "@/component/AppQrcode/app-qrcode";
import { isMobile } from '@/utils/common'
import starPng from '@/image/home/star.png'

interface Props {
  height: number;
}

interface State { }

export default class HeaderModule extends Component<Props, State> {
  refs: any = React.createRef()
  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <section className="header-module">
        <img className="first-star" src={starPng} alt="" />
        <img className="second-star" src={starPng} alt="" />
        <img className="third-star" src={starPng} alt="" />
        <Image className='home_1' src={isMobile() ? home_phone_1 : home_1} width={'100%'} height={this.props.height} fit='cover' lazy />
        <AppQrcode />
      </section>
    );
  }
}