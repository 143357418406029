import axios from "axios";
import { Toast } from 'antd-mobile'

// declare module "axios" {
//   export interface AxiosResponse<T = any> extends Promise<T> {}
// }

// export const Service = axios.create({
//   timeout: 3000, //延迟时间
//   method: "POST",
//   headers: {
//     "pc-token": "4a82b23dbbf3b23fd8aa291076e660ec",
//     "content-Type": "application/x-www-form-urlencoded",
//   },
// });

// //请求拦截
// Service.interceptors.request.use((config) => config);

// //响应拦截
// Service.interceptors.response.use(
//   (response) => response.data,
//   (err) => console.log(err)
// );

const core = process.env.REACT_APP_API_URL3
const api = process.env.REACT_APP_API_URL2
const v1 = process.env.REACT_APP_API_URL
const activity = process.env.REACT_APP_ACTIVITY_API_URL
const coreReg = /^\/core\/v1/
const apiReg = /^\/api\/v1/
const v1Reg = /^\/v1/
const activityReg = /^\/activity\/v1/

const whiteList = ['/']
const goLogin = () => {
  Toast.show('登录已过期')
  
  setTimeout(() => {
    sessionStorage.clear();
    localStorage.clear();

    if (!whiteList.includes(window.location.pathname)) window.location.href = '../?router=' + window.location.pathname
    else window.location.replace('/')
  }, 1000)
}

const checkStatus = (status:number | string) => {
  switch (status) {
    case 401: // 401: 未登录，未登录则跳转登录页面，并携带当前页面的路径
      goLogin();
      break;
    case 402: // 402 未授权
      break;
    case 403: // 403 token过期
      goLogin();
      break;
    case 404: // 404请求不存在
      break;
    default: // 其他错误，直接抛出错误提示
  }
}

axios.defaults.baseURL = "/";
axios.interceptors.request.use(
  //响应拦截
  async (config) => {
    if (apiReg.test(config.url || '')) config.url = api + '' + config.url
    else if (coreReg.test(config.url || '')) config.url = core + '' + config.url?.replace('/core', '')
    else if (v1Reg.test(config.url || '')) config.url = v1 + '' + config.url
    else if (activityReg.test(config.url || '')) config.url = activity + '' + config.url?.replace('/activity', '')
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    config.headers.token = localStorage.getItem("token") || '';
    return config;
  },
  (error) => {
    return Promise.reject(error).catch();
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const { code, msg } = response.data
      checkStatus(code)
      if (code === 0) {
        Toast.show(msg)
        return Promise.reject(response)
      }
      return Promise.resolve(response)
      //进行中
    } else {
      return Promise.reject(response).catch() //失败
    }
  }, // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      Toast.show({
        icon: 'fail',
        content: error.response.data,
      })
      checkStatus(error.response.status)
      return Promise.reject(error.response)
    }
  }
);

export const $get = (url: string, params?: object) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params })
      .then((res) => {
        if (!res.data?.data) res.data.data = []
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  }).catch(err => err)
};

export const $post = (url: string, params: object) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        if (!res.data?.data) res.data.data = []
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      })
  }).catch(err => err)
};
