import { $get, $post } from "@/utils/request";
import { MobileData, LoginData } from '@/interface/user';

// 获取安卓和IOS的下载二维码
export const getAccountInfo = (params: object) => {
  return $get(`/v1/user/view/info?user_id`, params).then((res: any) => res.data)
}

// 获取验证码
export const postMobileCode = (data: MobileData) => {
  return $post(`/v1/account/mobile/code`, data).then((res: any) => res.data)
}

// 登录
export const postLogin = (data: LoginData) => {
  return $post(`/v1/account/web/login`, data).then((res: any) => res.data)
}

// 根据token获取用户信息
export const getUserInfo = () => {
  return $get(`/v1/user/my/info`).then((res: any) => res.data)
}