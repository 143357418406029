import { Toast } from 'antd-mobile';
import wxPay from './wx'

// 利用时间戳实现节流算法，默认500毫秒
let timer:any = null;
const clearTimer = () => {
  clearTimeout(timer)
  timer = null
}

export const throttled = (fn: Function, delay:number = 500) => {
  if(timer) clearTimer()

  timer = setTimeout(() => {
    fn()
    timer = null
  }, delay)
}

const u = navigator.userAgent
export const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
export const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
export const isWeChat = /MicroMessenger/.test(u)
export const isWeChatMini = /miniProgram/i.test(u)
export const isWindows = u.includes('Windows') //Windows系统
export const isMac = u.includes('Intel Mac OS') //Mac系统

// 调用原生方法返回
export const onReturn = () => {
  try {
    if (isWeChat) {
      wxPay.closeWindow()
    }
    else if (isAndroid) {
      window.nativeObject?.close()
    }
    else if (isiOS) {
      window.webkit?.messageHandlers?.close?.postMessage(null);
    }
    else if (isWindows) {
      window.flutter_inappwebview?.callHandler('close')
    }
  } catch (error:any) {
    console.log(error)
  }
}

// 调用原生方法跳转到用户信息页
export const onToUserPage = (id: number, type?:string) => {
  if (type === 'room') {
    const obj = `{ "actiontype": "live_room", "params": {"id":  "${id.toString()}"}, "title": "" }`
    if (isWindows) {
      window.flutter_inappwebview?.callHandler('uuaudio', `uuaudio://action?dp_params=${encodeURIComponent(obj)}`)
    }
    else window.location.href = `uuaudio://action?dp_params=${encodeURIComponent(obj)}`
  } else {
    try {
      if (isAndroid) {
        window.nativeObject?.onToUserPage(id)
      }
      else if (isiOS) {
        window.webkit?.messageHandlers?.onToUserPage?.postMessage(id);
      }
      else if (isWindows) {
        window.flutter_inappwebview?.callHandler('onToUserPage', id)
      }
    } catch (error:any) {
      console.log(error)
    }
  }
}

// 处理键盘弹出遮挡输入框的问题
export const inputScrollView = (input:Element) => {
  const topHeight = document.body.scrollHeight;

  setTimeout(() => {
    if (isiOS) {
      if (!/OS 11_[0-3]\D/.test(u)) {
        document.body.scrollTop = topHeight;   
      }
    } else {
      input.scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    }
  }, 200);
}

// 判断用户是否安装了支付宝  0表示没有安装，1表示有，字符串类型
export const haveAlipay = () => {
  try {
    if (isAndroid) {
      const has:string = window.nativeObject?.haveAlipay()
      window.getHasAlipay(has)
    }
    else if (isiOS) {
      window.webkit?.messageHandlers?.havezf?.postMessage(null);
    }
  } catch (error:any) {
    console.log(error)
  }
}

export const isMQQ = () => {
  var _UA = window.navigator.userAgent;
  if(/QQ\/\d/i.test(_UA)){
      return true;
  }
  return false;
}

export const isWechat = () => {
  var _UA = window.navigator.userAgent;
  if(/micromessenger/i.test(_UA.match(/MicroMessenger/i)?.toString() || '')) {
      return true;
  }
  return false;
}

export const isAliPay = () => {
  var _UA = window.navigator.userAgent;
  if(/AlipayClient/i.test(_UA)){
      return true;
  }
  return false;
}

export const checkPhoneBranch = () => {
  const appInfo = navigator.userAgent;
  const ios = !!appInfo.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const iPad = appInfo.indexOf("iPad") > -1;
  const iPhone = appInfo.indexOf("iPhone") > -1 || appInfo.indexOf("Mac") > -1;
  const Android = appInfo.indexOf("Android") > -1 || appInfo.indexOf("Adr") > -1;
  const HarmonyOS = appInfo.indexOf("ohos") > -1
  return (ios || iPad || iPhone) ? 'IOS' : Android ? 'Android' : HarmonyOS ? 'HarmonyOS' : '其他'
}

export const jumpPage = (page:string) => {
  window.location.href = page
}

export const isMobile = () => {
  const isPhone = /Mobi/i.test(navigator.userAgent) || 'ontouchstart' in window || (navigator.maxTouchPoints > 0) || ((navigator as any).msMaxTouchPoints > 0);
  const clientWidth = document.body?.clientWidth || 390

  return isPhone || clientWidth <= 850
}

const execCommand = (textArea: any) => {
  // 使text area不在viewport，同时设置不可见
  textArea.style.position = "absolute";
  textArea.style.opacity = "0";
  textArea.style.left = "-999999px";
  textArea.style.top = "-999999px";
  document.body.appendChild(textArea);
  // textArea.focus();
  textArea.select();
  new Promise((resolve, reject) => {
    // 执行复制命令并移除文本框
    document.execCommand("copy") ? resolve(textArea.value) : reject(new Error("复制失败"));
    textArea.remove();
  }).then(() => {
    Toast.show('复制成功')
  }).catch(() => {
    Toast.show('浏览器复制功能被禁止，请检查浏览器设置！')
  })
}

export const ToggleCopy = async (text = '') => {
  const textArea = document.createElement("textarea");
  textArea.value = text.toString().replace(/"/g, '');
  
  if (navigator.clipboard && window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(textArea.value);
      Toast.show('复制成功')
    } catch (err) {
      // Toast.show('浏览器复制功能被禁止，请检查浏览器设置！')
      execCommand(textArea)
    }
  } else {
    execCommand(textArea)
  }
}
