// 导入路由组件
import Home from '../views/Home/Home'
import Recharge from '../views/Recharge/Recharge'
import About from '../views/About/About'
import Invite from '../views/Invite/Invite'
import RewardRules from '../views/RewardRules/reward-rules'
import Withdraw from '../views/Withdraw/Withdraw'
import MyWallet from '../views/MyWallet/MyWallet'
import MyInfo from '../views/MyInfo/MyInfo'
import OrderDetail from '../views/OrderDetail/OrderDetail'
import SigningPlatform from '../views/SigningPlatform/SigningPlatform'
import WithdrawRecord from '../views/Withdraw/Record'
import SubmitResult from '../views/SubmitResult/submit-result'
import errorPage from '../views/404/404'
import TotalTopUp from '../views/TopUpActivity/TotalTopUp/TotalTopUp'
import TopUpExplain from '../views/TopUpActivity/TopUpExplain/TopUpExplain'
import TopRoad from '../views/TopRoad/TopRoad'
import TopRoadReward from '../views/TopRoad/components/topRoadReward/topRoadReward'
import ValueNotice from '../views/Recharge/valueNotice'

// 导入路由管理工具
import { RouteConfig } from 'react-router-config'
import { getUserInfo } from '@/api/user'
import { postActivityDetail } from '@/api/invite'
import { store } from '@/store'
import { setUserInfo } from "@/store/userInfo/action"
import { isiOS, jumpPage } from '@/utils/common'

const routes: RouteConfig = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/recharge',
    exact: true,
    meta: { title: '充值' },
    component: Recharge
  },
  {
    path: '/about',
    exact: true,
    meta: { title: '关于我们' },
    component: About
  },
  {
    path: '/invite',
    exact: true,
    meta: { title: '邀请有礼' },
    component: Invite
  },
  {
    path: '/invite/reward-rules',
    exact: true,
    meta: { title: '奖励规则' },
    component: RewardRules
  },
  {
    path: '/withdraw/:platform_number',
    exact: true,
    component: Withdraw
  },
  {
    path: '/withdraw-record',
    exact: true,
    component: WithdrawRecord
  },
  {
    path: '/wallet',
    exact: true,
    meta: { title: '我的钱包' },
    component: MyWallet
  },
  {
    path: '/info',
    exact: true,
    meta: { title: '我的信息' },
    component: MyInfo
  },
  {
    path: '/order/:apply_number',
    exact: true,
    component: OrderDetail
  },
  {
    path: '/sign',
    exact: true,
    meta: { title: '签约' },
    component: SigningPlatform
  },
  {
    path: '/result',
    exact: true,
    component: SubmitResult
  },
  {
    path: '/top-up/total-top-up',
    exact: true,
    meta: { title: '充值有礼' },
    component: TotalTopUp
  },
  {
    path: '/top-up/explain',
    exact: true,
    component: TopUpExplain
  },
  {
    path: '/top-road',
    exact: true,
    meta: { title: '顶流之路' },
    component: TopRoad
  },
  {
    path: '/top-road-reward',
    exact: true,
    meta: { title: '规则' },
    component: TopRoadReward
  },
  {
    path: '/value-notice',
    exact: true,
    component: ValueNotice
  },
  {
    path: '*',
    exact: true,
    component: errorPage
  }
]

// const token = localStorage.getItem('token')
// const whiteList = ['/', '/about', '*']

let pathParams:any = {}
window.location.search.split('?').map((e:string) => {
  if (Object.keys(e).length) {
    // const arr:string[] = e.split('&').filter((e2:string) => e2.includes('token='));
    const arr:string[] = e.split('&');
    arr.map(e2 => {
      const tokenObj = e2.split('=')
      return pathParams[tokenObj[0]] = tokenObj[1]
    })
  }

  return pathParams
})

const { token, from } = pathParams;
if (from === 's') {
  jumpPage(isiOS ? 'uuaudio:' : 'bogokjmobclick:')
}

if(token && token !== 'undefined') {
  localStorage.setItem("token", token);
  // 如果拿到了token，就根据token直接获取用户信息
  getUserInfo().then(e => {
    // 存在localStorage里面是被充值账户的信息，而存在redux里面的是本账户的信息
    localStorage.setItem("userInfo", JSON.stringify(e));
    let action = setUserInfo(e)
    
    postActivityDetail({
      activity_code: 'YQ001'
    }).then(e2 => {
      const obj = { ...e, invite_code: e2.pretty_number }
      localStorage.setItem("userInfo", JSON.stringify(obj));
      action = setUserInfo(obj)
    }).finally(() => {
      store.dispatch(action)
    })
  }).catch(() => {})
}

export default routes
// export default routes.filter((e:any) => {
//   return token ? e : whiteList.includes(e.path)
// });